// warehouses.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const warehousesAPI = axios.create({
    baseURL,
});

export const getWarehouses = async (languageCode) => {
    const token = localStorage.getItem('token');
    try {
        const response = await warehousesAPI.get(`${baseURL}api/warehouses?language=${languageCode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};


export const addWarehouse = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await warehousesAPI.post(`${baseURL}api/warehouses`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteWarehouse = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await warehousesAPI.delete(`${baseURL}api/warehouses/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateWarehouse = async (id, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await warehousesAPI.put(`${baseURL}api/warehouses/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const increaseWarehouseCount = async (id,count) => {
    const token = localStorage.getItem('token');
    try {
        const response = await warehousesAPI.put(`${baseURL}api/warehouses/${id}/increase-count`, {count:count}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const decreaseWarehouseCount = async (id,count) => {
    const token = localStorage.getItem('token');
    try {
        const response = await warehousesAPI.put(`${baseURL}api/warehouses/${id}/decrease-count`, {count:count}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
