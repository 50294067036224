import React, {useEffect, useState} from "react";
import {Select, Timeline} from "antd";
import styles from "../../style.module.css";
import TimeLineItems from "../TimeLineItems/TimeLineItems";
import {useSelector} from "react-redux";
import {getHistoryTeen, getOrdersTeen} from "../../../../api/dashboard_api";

export default function Activity() {

    const theme = useSelector((state) => state.theme.theme);
    const [history,setHistory] = useState([]);

    useEffect(()=>{
        fetchHistory()
    },[])

    const getStatusColor = (status) => {
        switch (status) {
            case 'completed':
                return 'green';
            case 'pending':
                return 'orange';
            case 'on hold':
                return 'red';
            case 'created':
                return 'cyan';
            default:
                return 'blue';
        }
    };
    const timelineItems = history.map((value) => ({
        children: (
            <TimeLineItems
                color={getStatusColor(value.actions)}
                date={(<div className={`text-white w-full bg-${getStatusColor(value.actions)}-400 rounded p-1`}>
                    {value.actions}
                </div>)}
                when={value.created_at}
                title={value.actions}
                description={value.description}
            />
        ),
        color: getStatusColor(value.status),
    }));

    const fetchHistory = async ()=>{
        try {
            const historyFetch = await getHistoryTeen();
            setHistory(historyFetch);
        } catch (e){
            console.log(e)
        }

    }



    return<>
        <div className={` ${theme===`dark`?`bg-gray-800`:`bg-white`} duration-500 w-full h-full flex flex-col items-center rounded-2xl p-6`}>

            <div className={` ${theme===`dark`?`text-white`:`text-black`} duration-500 w-full flex items-center justify-between py-1`}>

                <div className={'text-2xl font-semibold'}>
                    Amallar
                </div>


            </div>

            <div className={styles.activity}>

                <Timeline
                    className={'w-full'}
                    mode="left"
                    items={timelineItems}
                />;

            </div>

        </div>
    </>
}
