import React from "react";
import styles from "./style.module.css";

import {Card, Col, Input, Row, Table, Typography} from "antd";
import {DollarOutlined, HeartOutlined, SearchOutlined, ShoppingCartOutlined, TeamOutlined} from "@ant-design/icons";
import Echart from "../chart/Echart";
import LineChart from "../chart/LineChart";
import {Pie} from "@ant-design/plots";
import PieChart from "../PieChart";

export default function Dashboard() {

    const { Title } = Typography;

    const count = [
        {
            today: "Today’s Sales",
            title: "$53,000",
            persent: "+30%",
            icon: <DollarOutlined />,
            bnb: "bnb2",
        },
        {
            today: "Today’s Users",
            title: "3,200",
            persent: "+20%",
            icon: <TeamOutlined />,
            bnb: "bnb2",
        },
        {
            today: "New Clients",
            title: "+1,200",
            persent: "-20%",
            icon: <HeartOutlined />,
            bnb: "redtext",
        },
        {
            today: "New Orders",
            title: "$13,200",
            persent: "10%",
            icon: <ShoppingCartOutlined />,
            bnb: "bnb2",
        },
    ];

    // Генерация случайного статуса
    const getRandomStatus = () => {
        const statuses = ['ordered', 'delivered'];
        return statuses[Math.floor(Math.random() * statuses.length)];
    };

// Генерация случайного номера телефона
    const getRandomPhoneNumber = () => {
        const randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
        return `+${randomNumber}`;
    };

    const orders = Array.from({ length: 15 }, (_, index) => ({
        key: index + 1,
        name: `Клиент ${index + 1}`,
        phoneNumber: getRandomPhoneNumber(),
        address: `Адрес ${index + 1}`,
        status: getRandomStatus(),
        deliveryPersonName: `Доставщик ${index + 1}`,
    }));


    // здесь весь Имена колонки и филтры для их
    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Адрес заказчика',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Статус заказа',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Ordered',
                    value: 'ordered',
                },
                {
                    text: 'Delivered',
                    value: 'delivered',
                },
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Имя доставщика',
            dataIndex: 'deliveryPersonName',
            key: 'deliveryPersonName',
        },
    ];


    return<>
        <div className={'layout-content w-full overflow-y-auto p-4'}>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {count.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                        className="mb-4 "
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">

                                <Row align="middle" gutter={[24, 0]}>
                                    <Col className={'w-full text-start'} xs={18}>
                                        <span className={'text-xl font-semibold font-mono whitespace-nowrap'}>{c.today}</span>
                                        <Title className={'flex flex-row items-end'} level={3}>
                                            <div className={'font-bold mr-4'}>{c.title}</div> <small style={{fontSize:'15px',fontWeight:'700', marginBottom:'7px'}} className={c.bnb}>{c.persent}</small>
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box pb-1 rounded bg-[#35a1fb] text-white text-center text-4xl ">{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-4">
                    <Card bordered={false} className="criclebox h-full">
                        <Echart />
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-4">
                    <Card bordered={false} className="criclebox h-full">
                        <LineChart />
                    </Card>
                </Col>
            </Row>

            <Row className={'w-full'}>
                <Table className={'w-full'} columns={columns} dataSource={orders} />
            </Row>
            {/*<PieChart/>*/}
        </div>
    </>
}