import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    DatePicker,
    Drawer,
    Dropdown,
    Form,
    Image,
    Input,
    message,
    Modal,
    Popconfirm,
    Select,
    Table,
    Upload
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {addOperator, deleteOperator, getOperators, showOperator, updateOperator} from "../../api/service_operators";
import {useSelector} from "react-redux";

export default function Operators() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [operators, setOperators] = useState([])
    const [operatorsOld, setOperatorsOld] = useState([])
    const [selectedOperator, setSelectedOperator] = useState([]);

    const [photoFile, setPhotoFile] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);

    const [image, setImage] = useState(null);

    const [imageDoc,setImageDoc] = useState(null)

    const [form] = Form.useForm();



    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleImage = (info) => {
        setImage(info.file)
    };

    const handleDocumentImage = (info) => {
        setImageDoc(info.file)
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchOperators()
    }, [])

    const fetchOperators = async () => {
        setLoading(true);
        try {
            const data = await getOperators();
            setOperators(data);
            setOperatorsOld(data)
        } catch (error) {
            console.error('Error fetching operators:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                values.photo = values.photo.file
                values.document_photo = values.document_photo.file

                await updateOperator(selectedOperator.id,values);
                setOperators(operators)
                message.success("админ успешно добавьлен в сервер")
                await fetchOperators()
            } else {
                values.photo = values.photo.file
                values.document_photo = values.document_photo.file
                await addOperator(values);
                setOperators(operators)
                message.success("админ успешно добавьлен в сервер")
                await fetchOperators()
            }
        } catch (error) {
            console.log('Error adding operators ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Full name',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Photo',
            render: (record) => {
                return <img src={`https://testarena.site/storage/${record}`} className={'w-20 h-20 object-cover'}/>
            },
            dataIndex: 'photo',
            key: 'photo',
        },
        {
            title: 'Document Photo',
            render: (record) => {
                return <img src={`https://testarena.site/storage/${record}`} className={'w-20 h-20 object-cover'}/>
            },
            dataIndex: 'document_photo',
            key: 'document_photo',
        },
        {
            title: 'Birth date',
            dataIndex: 'brithDate'
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showOperator(record.id);
                        setSelectedOperator(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setSelectedOperator(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the operator"
                        description="Are you sure to delete this operator?"
                        onConfirm={async () => {

                            await deleteOperator(record.id)
                            await fetchOperators()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: 'Full name',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Birth date',
            dataIndex: 'birthDate'
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(operators, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{},[operators])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setOperators(operatorsOld)
        } else {
            setOperators(filterOperators(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterOperators(value) {
        return operators.filter(operator => {

            return operator.fullname.toLowerCase().includes(value) || operator.phone.toLowerCase().includes(value);

        });
    }

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_operator}>

                <div className={styles.title}>
                    Operators
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Add a Operator +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the operator form" open={isModalOpen} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addOperators"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Full name"
                        name="fullname"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your fullname!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Phone number"
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input placeholder="Phone number" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please, enter password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item
                        label="Изображение"
                        name="photo"
                    >
                        <Upload
                            accept="image/*"
                            maxCount={1}
                            beforeUpload={() => false}
                            listType="picture"
                            showUploadList={{
                                showRemoveIcon: false,
                            }}
                            onChange={handleImage}
                        >
                            <Button icon={<UploadOutlined />}>Загрузить</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Изображение документа"
                        name="document_photo"
                    >
                        <Upload
                            accept="image/*"
                            maxCount={1}
                            beforeUpload={() => false}
                            listType="picture"
                            showUploadList={{
                                showRemoveIcon: false,
                            }}
                            onChange={handleDocumentImage}
                        >
                            <Button icon={<UploadOutlined />}>Загрузить</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Дата рождения"
                        name="brithDate"
                        rules={[
                            {
                                type: 'object',
                                required: true,
                                message: 'Please select your birthdate!',
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>

            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={operators} columns={columns}/>
            </div>

            <Drawer  title="Basic Drawer" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'w-9/12 h-fit'}>
                        <img src={`https://testarena.site/storage/${selectedOperator?.photo}`}
                             className={'w-full h-full object-cover'}/>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Full name : </p>

                        <p className={'text-xl font-semibold'}>{selectedOperator?.fullname}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Phone number : </p>

                        <p className={'text-xl font-semibold'}>{selectedOperator?.phone}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}