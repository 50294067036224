import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Select, Switch,
    Table
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";

import {Option} from "antd/es/mentions";
import {useSelector} from "react-redux";
import {addLanguage, deleteLanguage, getLanguage, showLanguage, updateLanguage} from "../../../api/language";
import {addCategoryType, getCategoryType, showCategoryType, updateCategoryType} from "../../../api/categoryType_api";
import {FiType} from "react-icons/fi";
import {getIcons} from "../../../api/Icons_api";
import Search from "antd/es/input/Search";

export default function CategoryType() {
    const BASE_URL = process.env.REACT_APP_URL
    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottles, setBottles] = useState([])
    const [bottlesOld, setBottlesOld] = useState([])
    const [selectedBottles, setSelectedBottles] = useState([]);

    const [bottlesTypes,setBottlesTypes] = useState([])
    const [selectedPath, setSelectedPath] = useState('');
    const [isCashback, setCashback] = useState(true);
    const [languages , setLanguages] = useState([]);
    const [filteredImages, setFilteredImages] = useState([]);
    const [images, setImages] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);


    const [form] = Form.useForm();



    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchLanguage()
        fetchBottles()
        fetchIcons()

    }, [])

    const fetchIcons= async () => {
        try {
            const data = await getIcons();
            setFilteredImages(data)
            setImages(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }

    const fetchLanguage = async () => {
        try {
            const data     =  await  getLanguage()
            console.log(data)
            setLanguages(data)
        } catch (e){

        }

    }

    const fetchBottles = async () => {
        let lang = 'uz'
        if (languages.length !==0){
            lang= languages[0].code;
        }
        setLoading(true);
        try {
            const data = await getCategoryType(lang);
            setBottles(data);
            setBottlesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };
    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return (
                value.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        setFilteredImages(filtered);
    };
    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                values.icon = selectedPath
                await updateCategoryType(selectedBottles.id,values);
                setBottles(bottles)
                message.success("Categoriya movoffaqiyatli o`zgartirildi")
                await fetchBottles()
            } else {
                values.icon = selectedPath
                await addCategoryType(values);
                setBottles(bottles)
                message.success("Categoriya movoffaqiyatli qo`shildi")
                await fetchBottles()
            }
        } catch (error) {
            console.log('Qo`shishda xatolik yuz berdi ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Turi',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'icon',
            render: (record) => {
                return <img src={`${BASE_URL}${record}`} className={'w-20 h-20 object-cover'}/>
            },
            dataIndex: 'icon',
            key: 'icon',
        },

        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showCategoryType(record.id);
                        setSelectedBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setEdit(true)
                        setSelectedBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the bottle type "
                        description="Are you sure to delete this type of bottle ?"
                        onConfirm={async () => {

                            await deleteLanguage(record.id)
                            await fetchBottles()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Turi',
            dataIndex: 'type',
            key: 'type',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(bottles, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{

    },[bottles])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setBottles(bottlesOld)
        } else {
            setBottles(filterBottles(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterBottles(value) {
        return bottles.filter(bottle => {

            return bottle.name.toLowerCase().includes(value) || bottle.bottle_type.name.toLowerCase().includes(value) || bottle.type.toLowerCase().includes(value) ;

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };



    console.log(bottlesTypes)

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Categoriya turlari
                    <FiType className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Categoriya turi qo`shish +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title={` Categoriya turi ${edit?' ni  o`zgartirish' : ' qo`shish'}`} open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="Categoriya Turini qo`shish"
                    initialValues={{ remember: true , cashbackPercent: 0}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Tilni tanlang"
                        name="language_id"
                        rules={[
                            {
                                required: true,
                                message: 'Tilni tanlang!',
                            },
                        ]}
                    >
                        <Select>
                            {languages?.map((type) => (
                                <Option value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Nomi"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Iltimos categoriya nomini kiriting!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Turi"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Iltimos categoriya turini kiriting!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                    <div className={'flex flex-row flex-wrap'}>

                        <Search
                            placeholder={'Kategoriya va nomi bo`yicha izlash '}
                            style={{width:'100%'}}
                            onSearch={handleSearch}
                        />
                    </div>

                    <div className={'flex flex-row mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-hidden'}>

                        {
                            filteredImages.map(value =><div className={'flex flex-col'}>
                                <img onClick={() => {
                                    setSelectedPath(value.icon)
                                    message.success(value.icon)
                                }
                                } src={`${BASE_URL}${value.icon}`}
                                     className={`w-20 h-20 flex cursor-pointer m-1  ${selectedPath === value.icon ? 'border-2 p-1 rounded-lg shadow-2xl border-blue-800':''}`}/>

                                <p className={`text-[12px] font-bold  ${selectedPath === value.icon ? 'text-white duration-200 bg-blue-800 rounded-lg' :''} text-blue-700  text-center`}>{value.name}</p>
                            </div>)
                        }
                    </div>

                </Form>



            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={bottles} columns={columns}/>
            </div>

            <Drawer  title="Categoriya turi" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Nomi : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottles?.name}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>turi : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottles?.type}</p>
                    </div>


                </div>


            </Drawer>
        </div>
    </div>
}
