import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Table,
    Upload
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, UploadOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {
    addWarehouse,
    deleteWarehouse,
    getWarehouses,
    showWarehouse,
    updateWarehouse
} from "../../api/service_warehouse";
import {useSelector} from "react-redux";

export default function Warehouse() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [warehouses, setWarehouses] = useState([])
    const [warehousesOld, setWarehousesOld] = useState([])
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);

    const [photoFile, setPhotoFile] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);

    const [image, setImage] = useState(null);

    const [imageDoc,setImageDoc] = useState(null)

    const [form] = Form.useForm();

    const theme = useSelector((state) => state.theme.theme);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setIsModalOpen(false)
        setEdit(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false)
        setEdit(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleImage = (info) => {
        setImage(info.file)
    };

    const handleDocumentImage = (info) => {
        setImageDoc(info.file)
    };


    useEffect(() => {
        fetchWarehouses()
    }, [])

    const fetchWarehouses = async () => {
        setLoading(true);
        try {
            const data = await getWarehouses();
            setWarehouses(data);
            setWarehousesOld(data)
        } catch (error) {
            console.error('Error fetching warehouses:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                await updateWarehouse(selectedWarehouse.id,values);
                setWarehouses(warehouses)
                message.success("склад успешно добавьлен в сервер")
                await fetchWarehouses()
            } else {
                await addWarehouse(values);
                setWarehouses(warehouses)
                message.success("склад успешно добавьлен в сервер")
                await fetchWarehouses()
            }
        } catch (error) {
            console.log('Error adding warehouses ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showWarehouse(record.id);
                        setSelectedWarehouse(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setSelectedWarehouse(record)
                        setEdit(true)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the warehouse"
                        description="Are you sure to delete this warehouse?"
                        onConfirm={async () => {

                            await deleteWarehouse(record.id)
                            await fetchWarehouses()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(warehouses, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{},[warehouses])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setWarehouses(warehousesOld)
        } else {
            setWarehouses(filterWareHouses(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterWareHouses(value) {
        return warehouses.filter(warehouse => {

            return warehouse.name.toLowerCase().includes(value) ;

        });
    }

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_warehouse}>

                <div className={styles.title}>
                    Warehouses
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Add a warehouse +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the Warehouse form" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addWarehouses"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>


            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={warehouses} columns={columns}/>
            </div>

            <Drawer  title="Склад" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Name : </p>

                        <p className={'text-xl font-semibold'}>{selectedWarehouse?.name}</p>
                    </div>

                </div>


            </Drawer>
        </div>
    </div>
}