import {BellOutlined, CheckOutlined, MailOutlined, PaperClipOutlined} from "@ant-design/icons";
import {BsBoxFill, BsTruck} from "react-icons/bs";
import {BiCart, BiError, BiUser} from "react-icons/bi";
import {TbLogout2} from "react-icons/tb";
import {MdOutlineAttachMoney} from "react-icons/md";
import React from "react";
import {FaHandHolding, FaStore, FaTshirt} from "react-icons/fa";
import {GiHomeGarage, GiReceiveMoney, GiWaterGallon} from "react-icons/gi";
import {PiBeerBottleLight} from "react-icons/pi";
import {IoPersonAddOutline} from "react-icons/io5";
import {LuWarehouse} from "react-icons/lu";
import {AiOutlineGift} from "react-icons/ai";
import {CiMail} from "react-icons/ci";

const ICONS = [
    {name:'bell', icon:<BellOutlined/>},
    {name:'truck', icon:<BsTruck/>},
    {name:'success', icon:<CheckOutlined/>},
    {name:'failed', icon:<BiError/>},
    {name:'user', icon:<BiUser/>},
    {name:'mail', icon:<MailOutlined/>},
    {name:'task', icon:<PaperClipOutlined/>},
    {name:'logout', icon:<TbLogout2 />},
    {name:'MdOutlineAttachMoney', icon:<MdOutlineAttachMoney />},
    {name:'FaHandHolding', icon:<FaHandHolding />},
    {name:'GiReceiveMoney', icon:<GiReceiveMoney />},
    {name:'BiCart', icon:<BiCart />},
    {name:'BsTruck', icon:<BsTruck />},
    {name:'GiHomeGarage', icon:<GiHomeGarage/>},
    {name:'GiWaterGallon', icon:<GiWaterGallon />},
    {name:'PiBeerBottleLight', icon:<PiBeerBottleLight/>},
    {name:'IoPersonAddOutline', icon:<IoPersonAddOutline/>},
    {name:'BsBoxFill', icon:<BsBoxFill/>},
    {name:'LuWarehouse', icon:<LuWarehouse/>},
    {name:'CiMail', icon:<CiMail/>},
    {name:'FaTshirt', icon:<FaTshirt/>},
    {name:'FaStore', icon:<FaStore/>},
]



export function IconHandler(type) {
    let handled = ICONS[0].icon
    ICONS.map(value=>{
        if (value.name === type){
            handled = value.icon
        }
    })
    return  handled
}