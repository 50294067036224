import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Select, Switch,
    Table
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";

import {Option} from "antd/es/mentions";
import {useSelector} from "react-redux";
import {addLanguage, deleteLanguage, getLanguage, showLanguage, updateLanguage} from "../../../api/language";

export default function Languages() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottles, setBottles] = useState([])
    const [bottlesOld, setBottlesOld] = useState([])
    const [selectedBottles, setSelectedBottles] = useState([]);

    const [bottlesTypes,setBottlesTypes] = useState([])

    const [isCashback, setCashback] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);


    const [form] = Form.useForm();



    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchBottles()

    }, [])

    const fetchBottles = async () => {
        setLoading(true);
        try {
            const data = await getLanguage();
            setBottles(data);
            setBottlesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                await updateLanguage(selectedBottles.id,values);
                setBottles(bottles)
                message.success("водитель успешно добавьлен в сервер")
                await fetchBottles()
            } else {
                await addLanguage(values);
                setBottles(bottles)
                message.success("админ успешно добавьлен в сервер")
                await fetchBottles()
            }
        } catch (error) {
            console.log('Error adding bottles types ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Codi',
            dataIndex: 'code',
            key: 'code',
        },

        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showLanguage(record.id);
                        setSelectedBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setEdit(true)
                        setSelectedBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the bottle type "
                        description="Are you sure to delete this type of bottle ?"
                        onConfirm={async () => {

                            await deleteLanguage(record.id)
                            await fetchBottles()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(bottles, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{

    },[bottles])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setBottles(bottlesOld)
        } else {
            setBottles(filterBottles(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterBottles(value) {
        return bottles.filter(bottle => {

            return bottle.name.toLowerCase().includes(value) || bottle.bottle_type.name.toLowerCase().includes(value) || bottle.Price.toLowerCase().includes(value) ;

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };



    console.log(bottlesTypes)

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Tillar
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                           Til qo`shish +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the bottle form" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addBottles"
                    initialValues={{ remember: true , cashbackPercent: 0}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Nomi"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the bottle name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Codi"
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the bottle name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>




                </Form>



            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={bottles} columns={columns}/>
            </div>

            <Drawer  title="Bottle" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Name : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottles?.name}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Bottle type : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottles?.BottlesType_id}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Cashback Price : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottles?.Price}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
