// driver-warehouses
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const authAPI = axios.create({
    baseURL,
});

export const getDriverWarehouses = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/driver-warehouses`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showDriverWarehouse = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/driver-warehouses/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addDriverWarehouse = async (driverWarehouseData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${baseURL}api/driver-warehouses`, driverWarehouseData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteDriverWarehouse = async (driverWarehouseId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.delete(`${baseURL}api/driver-warehouses/${driverWarehouseId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateDriverWarehouse = async (driverWarehouseId, driverWarehouseData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.put(`${baseURL}api/driver-warehouses/${driverWarehouseId}`, driverWarehouseData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
