// cards
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const authAPI = axios.create({
    baseURL,
});

export const getCards = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/cards`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showCard = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/cards/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addCard = async (cardData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${baseURL}api/cards`, cardData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteCard = async (cardId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.delete(`${baseURL}api/cards/${cardId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateCard = async (cardId, cardData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.put(`${baseURL}api/cards/${cardId}`, cardData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
