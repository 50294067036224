// bottles
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const bottlesAPI = axios.create({
    baseURL,
});

export const getLanguage = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/languages`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showLanguage = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/languages/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addLanguage = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.post(`${baseURL}api/languages`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteLanguage = async (LanguageID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.delete(`${baseURL}api/languages/${LanguageID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateLanguage = async (LanguageID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.put(`${baseURL}api/languages/${LanguageID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
