// store/index.js
import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './reducers/themeReducer';
import authReducer from "./reducers/authReducer";
import openReducer from "./reducers/openReducer";

const store = configureStore({
    reducer: {
        theme: themeReducer,
        auth: authReducer,
        open: openReducer, // Добавили новый редюсер
    },
});

export default store;
