// cash-back-history
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const authAPI = axios.create({
    baseURL,
});

export const getCashBackHistory = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/cash-back-history`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showCashBackRecord = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/cash-back-history/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addCashBackRecord = async (cashBackRecordData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${baseURL}api/cash-back-history`, cashBackRecordData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteCashBackRecord = async (cashBackRecordId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.delete(`${baseURL}api/cash-back-history/${cashBackRecordId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateCashBackRecord = async (cashBackRecordId, cashBackRecordData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.put(`${baseURL}api/cash-back-history/${cashBackRecordId}`, cashBackRecordData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
