import React from "react";
import {IconHandler} from "../../../../utils/iconHandler";


export default function Profile_Components(props) {
    return<>
        <div className={'w-full h-12 overflow-hidden border border-black/20 bg-gray-400/10 flex flex-row items-center justify-between rounded py-1 px-3 '}>
            <div className={'text-[20px] line-clamp-1'}>{IconHandler(`${props.type}`)}</div>
            <div className={'text-[15px] w-[75%] font-[600] line-clamp-1 font-[Montserrat, sans-serif]'}>{props.text}</div>
        </div>
    </>
}