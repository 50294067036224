import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Select, Switch,
    Table
} from "antd";
import {
    CheckOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PauseCircleTwoTone,
    PauseOutlined
} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {Option} from "antd/es/mentions";
import {getDriverWarehouses} from "../../api/service_driver_warehouses";
import {
    addHistoryRecord,
    deleteHistoryRecord,
    getHistory,
    showHistoryRecord,
    updateHistoryRecord
} from "../../api/service_history";
import {useSelector} from "react-redux";
import {TbTimelineEventPlus} from "react-icons/tb";
import {BiSolidTerminal} from "react-icons/bi";

export default function History() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [history, setHistory] = useState([])
    const [historyOld, setHistoryOld] = useState([])
    const [selectedHistory, setSelectedHistory] = useState([]);

    const [driverWarehouses,setDriverHouses] = useState([])

    const [isCashback, setCashback] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);


    const [form] = Form.useForm();

    const theme = useSelector((state) => state.theme.theme);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        fetchHistory()
        fetchDriverWarehouses()
    }, [])

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const data = await getHistory();
            setHistory(data);
            setHistoryOld(data)
        } catch (error) {
            console.error('Error fetching cars types:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                await updateHistoryRecord(selectedHistory.id,values);
                setHistory(history)
                message.success("водитель успешно добавьлен в сервер")
                await fetchHistory()
            } else {
                await addHistoryRecord(values);
                setHistory(history)
                message.success("машина успешно добавьлен в сервер")
                await fetchHistory()
            }
        } catch (error) {
            console.log('Error adding cashback History  ', error)
        }
    }
const actionHandler = (action)=>{
        switch (action){
            case 'hold':
                return (
                    <div className={'flex flex-row bg-red-400 rounded text-white'}>
                        <PauseOutlined className={'m-1'}/>
                        {action}
                    </div>
                )
            case 'pending':
                return (
                    <div className={'flex flex-row bg-orange-400 rounded text-white'}>
                        <ClockCircleOutlined className={'m-1'}/>
                        {action}
                    </div>
                )
            case 'completed':
                return (
                    <div className={'flex flex-row bg-green-600 rounded text-white'}>
                        <CheckOutlined className={'m-1'}/>
                        {action}
                    </div>
                )
            case 'created':
                return (
                    <div className={'flex flex-row bg-cyan-500 rounded text-white'}>
                        <TbTimelineEventPlus className={'m-1'}/>
                        {action}
                    </div>
                )


        }
}
    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            render:(actions)=>(
                actionHandler(actions)
            )
            // render: bottle_type => bottle_type.name
        },
        {
            title: 'Ma`lumot',
            dataIndex: ['description', 'actions'],
            key: 'description',
            render: (text, record) => (
                <div className={`${
                    record.actions === 'created' ? 'bg-cyan-500' :
                        record.actions === 'completed' ? 'bg-green-600' :
                            record.actions === 'pending' ? 'bg-orange-400' :
                                record.actions === 'hold' ? 'bg-red-400':
                                ''
                } text-white p-1 rounded`}>
                    {record.description}
                </div>
            )
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showHistoryRecord(record.id);
                        setSelectedHistory(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>

                    <Popconfirm
                        title="Delete the bottle type "
                        description="Are you sure to delete this type of bottle ?"
                        onConfirm={async () => {

                            await deleteHistoryRecord(record.id)
                            await fetchHistory()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: bottle_type => bottle_type.name
        },
        {
            title: 'User',
            dataIndex: 'user_id'
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(history, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{

    },[history])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setHistory(historyOld)
        } else {
            setHistory(filterHistory(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterHistory(value) {
        return history.filter(history => {

            return history.name.toLowerCase().includes(value) || history.action.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const fetchDriverWarehouses = async () => {
        setLoading(true);
        try {
            const data = await getDriverWarehouses();
            setDriverHouses(data);
        } catch (error) {
            console.error('Error fetching cars types:', error);
        }
        setLoading(false);
    };

    console.log(driverWarehouses)

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Amallar
                    <BiSolidTerminal className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>


                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    {/*<div className={'flex flex-row items-center'}>*/}
                    {/*    <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>*/}
                    {/*    <Input*/}
                    {/*        placeholder="input search text"*/}
                    {/*        allowClear*/}
                    {/*        onChange={onSearch}*/}
                    {/*        style={{*/}
                    {/*            width: 200,*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</div>*/}

                </div>

            </div>

            <Modal title="Fill the bottle form" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addCashbackHistory"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="Name record"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the record name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label="Action"
                        name="action"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the action name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                    {/*There will be users' list to select*/}
                    {/*<Form.Item*/}
                    {/*    label="Name"*/}
                    {/*    name="name"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*            message: 'Please select the  type!',*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Select>*/}
                    {/*        {driverWarehouses?.map((type) => (*/}
                    {/*            <Option value={type.id}>*/}
                    {/*                {type.name}*/}
                    {/*            </Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}

                    <Form.Item
                        label="User"
                        name="user_id"
                        rules={[
                            {
                                type:"number",
                                required: true,
                                message: 'Please input the user!',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    {/*There will be confirmed users' list to select*/}
                    {/*<Form.Item*/}
                    {/*    label="Name"*/}
                    {/*    name="name"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*            message: 'Please select the  type!',*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Select>*/}
                    {/*        {driverWarehouses?.map((type) => (*/}
                    {/*            <Option value={type.id}>*/}
                    {/*                {type.name}*/}
                    {/*            </Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}



                    <Form.Item
                        label="Confirmed User"
                        name="confirm_user_id"
                        rules={[
                            {
                                type:"number",
                                required: true,
                                message: 'Please input the user!',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                </Form>



            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={history} columns={columns}/>
            </div>

            <Drawer  title="Cashback History" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>User : </p>

                        <p className={'text-xl font-semibold'}>{selectedHistory?.name}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}> Action name : </p>

                        <p className={'text-xl font-semibold'}>{selectedHistory?.action}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}> Name record : </p>

                        <p className={'text-xl font-semibold'}>{selectedHistory?.user_id}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}> Name record : </p>

                        <p className={'text-xl font-semibold'}>{selectedHistory?.confirm_user_id}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
