import React from 'react'
import {Select} from "antd";
import {Option} from "antd/es/mentions";
import uz_flag from "../../../../assets/icons/flags/uz_flag_icon.png";
import ru_flag from "../../../../assets/icons/flags/ru_flag_icon.png";
import en_flag from "../../../../assets/icons/flags/en_flag_icon.png";
import "./flagSelector.css";
import {useSelector} from "react-redux";

export default function  FlagSelector(){

    const theme = useSelector((state) => state.theme.theme);

    return  <div className={`${theme===`dark`? `custom_select` : ``} `}>
        <Select defaultValue="usa" style={{ width: 60 }}>
            <Option value="usa">
                <img src={uz_flag} alt="USA Flag" style={{ width: '20px', marginRight: '8px' }} />
            </Option>
            <Option value="uk">
                <img src={ru_flag} alt="UK Flag" style={{ width: '20px', marginRight: '8px' }} />
            </Option>
            <Option value="germany">
                <img src={en_flag} alt="Germany Flag" style={{ width: '20px', marginRight: '8px' }} />
            </Option>
        </Select>
    </div>

}