import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {setTheme} from "../../store/actions/themeActions";
import {useDispatch, useSelector} from "react-redux";
import {setOpen} from "../../store/actions/openActions";
import {BsSquareHalf} from "react-icons/bs";
import placeholder from "../../assets/images/placeholder.jpg";
import {IoMoonOutline, IoSunnyOutline, IoTrashOutline} from "react-icons/io5";
import { Dropdown, Modal} from "antd";
import {LuBell} from "react-icons/lu";
import FlagSelector from "./components/flagSelector/FlagSelector";
import NofiticationBar from "./components/Notification/NotificationComponents";
import Profile_Components from "./components/Profile_Notification/Profile_Components";
import {message} from "antd";

export default function Content_Header() {

    const [isModalOpen,setModalOpen] = useState(false);



    const user = localStorage.getItem('user');
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme.theme);

    const isOpen = useSelector((state) => state.open.isOpen);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        dispatch(setTheme(newTheme));
    };

    const toggleOpen = () => {
        dispatch(setOpen(!isOpen)); // Инвертируем текущее значение isOpen
    };

    const notification_items = [
        {
            key: '1',
            label: (
                <div className={'w-60 h-auto gap-y-2 flex flex-col items-center bg-white'}>
                    <NofiticationBar text={'SDASDSADSADA'} type={'truck'} color={'black'}/>
                    <NofiticationBar text={'SEGAAA'} type={'truck'} color={'red'}/>
                </div>
            ),
        },
    ];
    const selected_items = [
        {
            key: '1',
            label: (
                <div className={'w-60 h-auto gap-y-2 flex flex-col items-center bg-white'}>
                    <NofiticationBar text={'SDASDSADSADA'} type={'truck'} color={'black'}/>
                    <NofiticationBar text={'SEGAAAasdasd'} type={'truck'} color={'red'}/>
                </div>
            ),
        },
    ];

    const profile_items = [
        {
            key: '1',
            label: (
                <div className={'w-40 h-auto gap-y-2 flex flex-col items-center '}>
                    <Profile_Components type={'user'} text={'Account'}/>
                    <Profile_Components type={'mail'} text={'Inbox'}/>
                    <Profile_Components type={'task'} text={'Taskboard'}/>
                    <div onClick={()=>setModalOpen(true)} className={'w-full'}>
                        <Profile_Components type={'logout'} text={'Log Out'}/>
                    </div>
                </div>
            )
        }
    ]

    const handleCancel = () => {
        setModalOpen(false)
    }

    const handleOk = async () => {
        localStorage.removeItem('token')
        setModalOpen(false)
        await window.location.reload()
    }


    return <>
        <Modal okButtonProps={{className:`bg-blue-400`}} title="Are you sure to leave ?" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}></Modal>
        <div className={`  ${styles.container}`}>

            <div className={styles.theme_tools}>

                <div className={'flex flex-row items-center gap-x-2'}>
                    <div className={`text-xl ${theme === `dark`? `text-[#f3f5f6]`: `text-[#4d4d4d]`} `} onClick={toggleOpen}><BsSquareHalf/></div>

                    <div onClick={toggleTheme}
                         className={`w-10 h-10 ${theme === `dark` ? `bg-black` : `bg-[#f5f5f5] `} duration-500 rounded-lg relative overflow-hidden`}>
                        <IoSunnyOutline
                            className={` ${theme === `dark` ? `-left-20` : ` left-1`} duration-500 text-amber-500 text-3xl absolute top-1 `}/>
                        <IoMoonOutline
                            className={` ${theme === `dark` ? `right-1` : ` -right-20`} duration-500 text-white text-3xl absolute top-1 `}/>
                    </div>

                    <div className={`w-32 h-8 flex flex-col overflow-hidden items-center ${theme === `dark`? `text-[#f3f5f6]`: `text-[#4d4d4d]`}`}>
                        <p className={` ${theme === `dark` ? `-translate-y-20` : `-translate-y-4`} text-[17px] font-semibold duration-500 `}>Kunduzgi  </p>
                        <p className={` ${theme === `dark` ? `-translate-y-20` : `translate-y-20`} text-[17px] font-semibold duration-500 `}>Tungi </p>
                    </div>
                </div>

            </div>

            <div className={styles.profile_tools}>

                {/*Profile items*/}
                <Dropdown
                    menu={{
                        items: profile_items,
                    }}
                    placement="bottom"
                    arrow
                >

                    <div className={`${theme===`dark`?`text-white`:`text-black`} flex flex-row items-center h-10`}>

                        <div className={'w-10 h-10 rounded-full overflow-hidden'}>
                            <img src={placeholder} className={'w-full h-full object-cover'}/>
                        </div>

                        <div className={'overflow-hidden relative  text-center pl-1 w-40 h-12'}>
                            <span
                                className={'font-semibold text-[17px] absolute -top-5 line-clamp-1'}>{user}</span>
                            <span className={'font-thin text-[14px] absolute top-1 line-clamp-1'}>Admin</span>
                        </div>
                    </div>

                </Dropdown>

                {/*Notification*/}
                <Dropdown
                    menu={{
                        items: notification_items,
                    }}
                    placement="bottom"
                    arrow
                >
                    <div className={'text-amber-500 text-2xl overflow-hidden'}>
                        <LuBell/>
                    </div>
                </Dropdown>

                {/*Selected products or something*/}
                <Dropdown
                    menu={{
                        items: selected_items,
                    }}
                    placement="bottom"
                    arrow
                >
                    <div className={' text-amber-500 text-2xl overflow-hidden'}>
                        <IoTrashOutline/>
                    </div>
                </Dropdown>
                <FlagSelector/>

            </div>

        </div>
    </>
}
