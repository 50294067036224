/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import eChart from "../configs/eChart";

function EChart() {
    const { Title, Paragraph } = Typography;

    const items = [
        {
            Title: "3,6K",
            user: "Users",
        },
        {
            Title: "2m",
            user: "Clicks",
        },
        {
            Title: "$772",
            user: "Sales",
        },
        {
            Title: "82",
            user: "Items",
        },
    ];

    return (
        <>
            <div id="chart">
                <ReactApexChart
                    className="bar-chart bg-[#35a1fb] rounded"
                    options={eChart.options}
                    series={eChart.series}
                    type="bar"
                    height={220}
                />
            </div>
            <div className="chart-vistior w-full text-start">
                <Title level={3}>Active Users</Title>
                <Paragraph className="lastweek text-[18px] font-semibold opacity-75">
                    than last week <span className="bnb2 text-green-600 font-semibold">+30%</span>
                </Paragraph>
                <Paragraph className="lastweek text-[18px] font-semibold opacity-75">
                    We have created multiple options for you to put together and customise
                    into pixel perfect pages.
                </Paragraph>
                <Row className={'bg-[#35a1fb] text-white rounded'} gutter>
                    {items.map((v, index) => (
                        <Col xs={6} xl={6} sm={6} md={6} key={index}>
                            <div className="chart-visitor-count text-center">
                                <Title level={4}><div className={'text-white'}>{v.Title}</div></Title>
                                <span>{v.user}</span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
}

export default EChart;
