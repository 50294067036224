// bottles
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const bottlesAPI = axios.create({
    baseURL,
});

export const getOrdersTeen = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/dashboard-order`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const getProductCount = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/dashboard-products`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const getUSersCount = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/dashboard-users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const getHistoryTeen = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/dashboard-history`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};







