import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button, Carousel,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Popover, Select, Switch,
    Table, Typography
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    LeftOutlined,
    RightOutlined
} from "@ant-design/icons";
import {LiaProductHunt, LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel, SiSalesforce, SiShortcut} from "react-icons/si";

import {Option} from "antd/es/mentions";
import {useSelector} from "react-redux";
import {addLanguage, deleteLanguage, getLanguage, showLanguage, updateLanguage} from "../../../api/language";
import {addIcons, getIcons, updateIcons} from "../../../api/Icons_api";
import {getImages} from "../../../api/imageManager";
import Search from "antd/es/input/Search";
import {
    addCategory,
    deleteCategory,
    getCategory,
    getCategoryByLang,
    showCategory,
    updateCategory
} from "../../../api/Category_api";
import TextArea from "antd/es/input/TextArea";
import {getCategoryType} from "../../../api/categoryType_api";
import {addProducts, deleteProducts, getProducts, showProducts, updateProducts} from "../../../api/products_api";
import {number} from "prop-types";
import {getBrands} from "../../../api/Brands_api";
import {ImImage} from "react-icons/im";
import {BiMoney} from "react-icons/bi";
import {FcSalesPerformance} from "react-icons/fc";
import {addNews, deleteNews, getNews} from "../../../api/ACC/NewsApi";
import {addSlide, deleteSlide, getSlider, updateSlide} from "../../../api/ACC/SliderApi";

export default function Slider() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottles, setBottles] = useState([])
    const [bottlesOld, setBottlesOld] = useState([])
    const [news, setNews] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedBottles, setSelectedBottles] = useState([]);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [open, setOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [selectedlanguages, setSelectedLanguages] = useState([]);
    const [types, setTypes] = useState([]);
    const [brandId, setBrandId] = useState();
    const [brief, setBreif] = useState('');
    const [description, setDescription] = useState('');
    const [topIcons, setTopIcons] = useState([]);
    const [covers, setcovers] = useState([]);
    const [isIcon, setIsIcon] = useState(true)
    const [filteredImages, setFilteredImages] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectDescription, setSelectDescription] = useState();
    const [form] = Form.useForm();
    const [isCashBack, setIsCashback] = useState(false);

    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const [modalVisibleTable, setModalVisibleTable] = useState(false);
    const [selectedImageTable, setSelectedImageTable] = useState('');

    const handleImageClick = (imageUrl) => {
        setSelectedImageTable(imageUrl);
        setModalVisibleTable(true);
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleOk2 = () => {
        setIsModalOpen2(false);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchLanguage()
        fetchBottles()
        fetchNews()
        fetchCategories()
        fetchImagees()
        // fetchBrands()

    }, [])

    // const fetchBrands = async () => {
    //     try {
    //         const data = await getBrands();
    //         setBrands(data);
    //         console.log(data)
    //     } catch (error) {
    //         console.error('Error fetching bottles types:', error);
    //     }
    // }

    const fetchImagees = async () => {
        try {
            const data = await getImages();
            setFilteredImages(data)
            setImages(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }
    const fetchNews = async () => {
        setLoading(true);
        try {
            let lang = 'uz'
            if (languages.length !== 0) {
                lang = languages[0].code;
            }
            const data = await getNews(lang);
            setNews(data);

        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };

    const fetchCategories = async () => {
        setLoading(true);
        try {
            let lang = 'uz'
            if (languages.length !== 0) {
                lang = languages[0].code;
            }
            const data = await getCategoryByLang(lang);
            setCategories(data);

        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };

    const fetchBottles = async () => {
        setLoading(true);
        try {
            let lang = 'uz'
            if (languages.length !== 0) {
                lang = languages[0].code;
            }
            const data = await getSlider(lang);
            setBottles(data);
            setBottlesOld(data)
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit) {
                // values.topicons = topIcons
                values.brief_description = brief
                values.description = description

                values.cover = '/' + covers[0]
                // values.brands_id = brandId
                await updateSlide(selectedBottles.id, values);
                setBottles(bottles)
                message.success("Slider muvoffaqiyatli o`zgardi")
                await fetchBottles()
            } else {
                // values.topicons = topIcons
                values.brief_description = brief
                // values.cashback_price = isCashBack? values.cashback_price : 0
                values.description = description
                values.cover = '/' + covers[0]
                // values.brands_id = brandId
                console.log(values)
                message.success(covers[0])
                await addSlide(values);
                setBottles(bottles)
                message.success("Slider muvoffaqiyatli qo`shildi")
                await fetchBottles()
            }
        } catch (error) {
            console.log('Error adding bottles types ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Kategoriyasi',
            dataIndex: 'category',
            key: 'category',
            render:category => category?.name
        },
        {
            title: 'yangilik',
            dataIndex: 'news',
            key: 'news',
            render:news => news?.brief_description
        },
        {
            title: 'tili',
            dataIndex: 'language',
            key: 'language',
            render:language => language?.name
        },
        {
            title: (
                <div className={'flex items-center justify-center'}>
                    <InfoCircleOutlined className={'mr-[5px] text-teal-700'}/>
                    <span className={'text-teal-700'}>To`liq ma`lumot</span>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            render: (text) => (
                <span
                    className={'bg-blue-800 text-white rounded cursor-pointer p-1 text-center w-full '}

                    onClick={() => {
                        showModal2();
                        setSelectDescription(text);
                    }}
                >
            {text?.length > 40 ? `${text?.substring(0, 40)}...` : text}
        </span>
            ),
        },

        {
            title: (
                <div className={'flex items-center justify-center'}>
                    <ImImage className={'mr-[5px] text-blue-900'}/>
                    <span className={'text-blue-900'}>Muqovalar</span>
                </div>
            ),
            dataIndex: 'cover',
            key: 'cover',
            render: (cover) => (
                <div className={'w-full'}>

                    <img

                        onClick={() => handleImageClick(process.env.REACT_APP_URL + `/${cover}`)}
                        src={`${process.env.REACT_APP_URL}/${cover}`}
                        className={'inline-block mr-2 mb-2 w-[25px] h-[25px] cursor-pointer'}
                        alt={`type`}
                    />

                </div>
            )

        }
        ,


        {
            title: 'amallar',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={async () => {
                        const response = await showProducts(record.id);
                        setSelectedBottles(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined/>
                    </div>
                    <div onClick={() => {
                        setEdit(true)
                        setSelectedBottles(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Yangilikni o`chirish "
                        description="Haqiqatdan ham o`chirishni xohlaysizmi ?"
                        onConfirm={async () => {

                            await deleteSlide(record.id)
                            await fetchBottles()
                        }
                        }
                        okText="ha"
                        cancelText="yo`q"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(bottles, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return (
                value.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                value.img_category.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        setFilteredImages(filtered);
    };

    const fetchLanguage = async () => {
        try {
            const data = await getLanguage()
            console.log(data)
            setLanguages(data)
        } catch (e) {

        }

    }
    const fetchType = async () => {
        try {
            const data = await getCategoryByLang('uz');
            console.log(data)
            setTypes(data)
        } catch (e) {

        }
    }
    useEffect(() => {

    }, [bottles])

    const onSearch = (values) => {

        if (values.target.value.length === 0) {
            setBottles(bottlesOld)
        } else {
            setBottles(filterBottles(values.target.value.toLowerCase()))
        }


    }

    const iconsHandler = (value) => {
        let newIcons;
        // Создаем новый массив на основе текущего состояния topIcons или covers
        if (!isIcon) {
            newIcons = [...topIcons];
        } else {
            newIcons = [...covers];
        }
        const index = newIcons.indexOf(value);

        if (index === -1) {
            // Если значение не найдено в массиве, добавляем его
            newIcons.push(value);
        } else {
            // Если значение найдено в массиве, удаляем его
            newIcons.splice(index, 1);
        }

        // Обновляем состояние topIcons или covers новым массивом в зависимости от условия
        !isIcon ? setTopIcons(newIcons) : setcovers(newIcons);
        console.log(topIcons);
        console.log(covers);
    };


    // axios.put(baseUrl, data)
    function filterBottles(value) {
        return bottles.filter(bottle => {

            return bottle.name.toLowerCase().includes(value) || bottle.bottle_type.name.toLowerCase().includes(value) || bottle.Price.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };
    const handleLanguage = async (e) => {
        setLoading(true);
        try {
            const data = await getProducts(e);
            setBottles(data);
            setBottlesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);

    }


    const search = (value) => {
        message.info(value)
    }
    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Sliderlar
                    <LiaProductHunt className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Slider qo`shish +
                        </Button>

                        <Button type={"primary"} onClick={() => {
                            handleClick()
                        }}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel/>
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <Select placeholder={'Tilni tanlang'} className={'w-[200px] mr-2'} onChange={handleLanguage}>
                            {languages?.map((type) => (
                                <Option value={type.code}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                        <div
                            className={`font-sans font-semibold ${theme === 'dark' ? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Izlash
                        </div>
                        <Input
                            placeholder="Izlash"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Maxsulotlar" open={isModalOpen || edit} okButtonProps={confirmButtonProps}
                   onOk={handleOk} onCancel={handleCancel} bodyStyle={{height: '550px', overflow: 'scroll'}}>
                <Form
                    form={form}
                    name="Maxsulot qo`shish"
                    initialValues={{remember: true, cashbackPercent: 0}}
                    onFinish={onFinish}
                >


                    <Form.Item
                        label="Tilni tanlang"
                        name="language_id"
                        rules={[
                            {
                                required: true,
                                message: 'Tilni tanlang!',
                            },
                        ]}
                    >
                        <Select>
                            {languages?.map((type) => (
                                <Option value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item
                        label="Categoriyani tanlang"
                        name="category_id"
                        rules={[
                            {
                                required: true,
                                message: 'categoriyani tanlang!',
                            },
                        ]}
                    >
                        <Select>
                            {categories?.map((type) => (
                                <Option value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Yangilikni tanlang"
                        name="news_id"
                        rules={[
                            {
                                required: true,
                                message: 'yangilinkni tanlang!',
                            },
                        ]}
                    >
                        <Select>
                            {news?.map((type) => (
                                <Option value={type.id}>
                                    {type.brief_description}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>



                    <div className={'w-full '}>
                        <p className={'text font-bold text-blue-700 text-2xl mt-2'}> To`liqq ma`lumot </p>
                        <TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Controlled autosize"
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />
                        <p className={'text font-bold text-blue-700 text-2xl mt-2'}> Qisqacha ma`lumot </p>
                        <TextArea
                            value={brief}
                            onChange={(e) => setBreif(e.target.value)}
                            placeholder="Controlled autosize"
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />

                    </div>


                    <div className={'flex flex-row flex-wrap'}>

                        <Search
                            placeholder={'Kategoriya va nomi bo`yicha izlash '}
                            style={{width: '100%'}}
                            onSearch={handleSearch}
                        />
                    </div>
                    <div className={'flex flex-row flex-wrap mt-4 justify-center items-center mb-2 ml-2 w-full'}>
                        <p className={'font-bold text-[18px] text-blue-700'}> Muqova va Iconkani qo`shish</p>


                        <p className={' text-[24px]  ml-8 font-bold text-green-700'}> Muqova</p>

                    </div>
                    {
                        <div
                            className={'flex flex-row flex-wrap w-full mt-1 rounded-lg border border-blue-700 p-2 h-[400px] overflow-scroll'}>

                            {filteredImages.map(value => (
                                <div className={'flex flex-col ml-1'}>
                                    <img
                                        onClick={() => {
                                            setSelectedPath(value.id)
                                            message.success(value.path)
                                            iconsHandler(value.path)
                                        }}
                                        src={`${process.env.REACT_APP_URL}${value.path}`}
                                        className={`w-20 h-20 flex cursor-pointer m-1   ${
                                            covers.includes(value.path)
                                                ? 'text-white duration-200 border-blue-700 border-2 rounded-lg'
                                                : 'text-blue-700'
                                        }`}
                                    />

                                    <p
                                        className={`text-[12px] font-bold  ${

                                            covers.includes(value.path)
                                                ? 'text-white duration-200 bg-blue-800 rounded-lg'
                                                : ''
                                        } text-blue-700  text-center`}
                                    >
                                        {value.name}
                                    </p>
                                </div>
                            ))
                            }
                        </div>
                    }


                </Form>


            </Modal>

            <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={bottles} columns={columns}/>
            </div>

            <Drawer title={`${selectedBottles?.name}`} onClose={onClose} open={open}>
                <Typography
                    className={' self-start ml-2  bg-blue-700 text-white text-center rounded mb-2'}>Muqovalar </Typography>
                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <Carousel style={{width: '300px', borderRadius: "10px"}}>
                        {
                            selectedBottles?.cover?.map(value =>
                                <div>
                                    <img src={`${process.env.REACT_APP_URL}${value}`} className={'w-full h-[300px]'}/>
                                </div>
                            )
                        }
                    </Carousel>

                    <div className={'flex flex-row w-full'}>
                        <BiMoney className={'text-green-600 text-4xl'}/>
                        <p className={'text-[13px]  ml-2 bg-green-800 text-white rounded-lg p-1 mt-1'}>Maxsulot narxi
                            : {selectedBottles?.price} so`m</p>
                    </div>
                    <Typography className={' self-start ml-2 text-3xl text-gray-400'}>To`liq ma`lumot </Typography>

                    <div className={'bg-blue-800 text-white text-[14px] w-full p-4 rounded-lg'}>
                        <p className={'text-white text-[14px]'}>
                            {selectedBottles?.description}
                        </p>
                    </div>
                    <Typography className={' self-start ml-2 text-3xl text-gray-400'}>Qisqacha ma`lumot </Typography>

                    <div className={'bg-blue-800 text-white text-[14px] w-full p-4 rounded-lg'}>
                        <p className={'text-white text-[14px]'}>
                            {selectedBottles?.brief_description}
                        </p>
                    </div>


                </div>


            </Drawer>
        </div>
        <Modal
            visible={modalVisibleTable}
            onCancel={() => setModalVisibleTable(false)}
            footer={null}
        >
            {/* Отображение выбранного изображения в модальном окне */}
            <img src={selectedImageTable} alt="Selected Image" style={{width: '100%'}}/>
        </Modal>
        <Modal
            title="To`liq Ma`lumot"
            open={isModalOpen2}
            onOk={handleOk2}
            onCancel={handleCancel2}
            cancelText={'ortga'}
            okText={'tasdiqlash'}
            okButtonProps={{style: {backgroundColor: '#3742fa'}}} // Зеленый цвет для кнопки "Ok"
            cancelButtonProps={{
                style: {
                    backgroundColor: '#7bed9f',
                    color: 'white'
                }
            }} // Красный цвет для кнопки "Cancel"
        >
            <p>{selectDescription}</p>
        </Modal>

    </div>
}
