// bottles
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const bottlesAPI = axios.create({
    baseURL,
});

export const getProducts = async (lang) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/products/?language=${lang === null?'uz':lang}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const showProducts = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.get(`${baseURL}api/products/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addProducts = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.post(`${baseURL}api/products`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};


export const deleteProducts = async (LanguageID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.delete(`${baseURL}api/products/${LanguageID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateProducts = async (LanguageID, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await bottlesAPI.put(`${baseURL}api/products/${LanguageID}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
