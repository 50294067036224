// operators
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const authAPI = axios.create({
    baseURL,
});

export const getOperators = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/operators`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401 ){
            localStorage.removeItem('token')
        }
        throw error;
    }
};

export const showOperator = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/operators/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401 ){
            localStorage.removeItem('token')
        }
        throw error;
    }
};

export const addOperator = async (operatorData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${baseURL}api/operators`, operatorData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401 ){
            localStorage.removeItem('token')
        }
        throw error;
    }
};

export const deleteOperator = async (operatorId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.delete(`${baseURL}api/operators/${operatorId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateOperator = async (operatorId, operatorData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.put(`${baseURL}api/operators/${operatorId}`, operatorData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
