import React, {useEffect, useState} from "react";
import {Form, message, notification, Popconfirm, Popover, Select, Table} from "antd";
import styles from "../../style.module.css";
import PersonItems from "../PersonItems/PersonItems";
import {useSelector} from "react-redux";
import {getOrdersTeen} from "../../../../api/dashboard_api";
import {
    changeOrderCourier,
    completedOrder, deleteOrders,
    getOrders,
    getTotalHoldLastweekOrder,
    getTotalLastweekOrder,
    getTotalPendingLastweekOrder, holdOrder, makeOrder, pendingOrder
} from "../../../../api/orders_api";
import {getBrands} from "../../../../api/Brands_api";
import {getImages} from "../../../../api/imageManager";
import moment from "moment";
import {getProducts, showProducts, updateProducts} from "../../../../api/products_api";
import {GrCircleInformation} from "react-icons/gr";
import {HiOutlineInformationCircle, HiOutlineTrendingUp} from "react-icons/hi";
import {
    CheckOutlined, ClockCircleOutlined, DeleteOutlined, EditOutlined,
    EyeOutlined,
    OrderedListOutlined,
    PauseCircleFilled,
    PhoneOutlined, ReloadOutlined,
    UserOutlined
} from "@ant-design/icons";
import {ImAddressBook} from "react-icons/im";
import {AiOutlineClockCircle} from "react-icons/ai";
import {BiCheckCircle, BiPauseCircle, BiTime} from "react-icons/bi";
import {getLanguage} from "../../../../api/language";
import {getCategoryType} from "../../../../api/categoryType_api";
import {MdBookmarkBorder} from "react-icons/md";
import {CgChevronDoubleDown, CgChevronDoubleUp} from "react-icons/cg";
import {BsCalendar2Date} from "react-icons/bs";
import {getUser} from "../../../../api/users_api";
import {Option} from "antd/es/mentions";

export default function RecentSales() {


    const [lastTeenOrders, setLastTeenOrders] = useState([]);

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottles, setBottles] = useState([])
    const [bottlesOld, setBottlesOld] = useState([])
    const [selectedBottles, setSelectedBottles] = useState([]);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [open, setOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [selectedlanguages, setSelectedLanguages] = useState([]);
    const [types, setTypes] = useState([]);
    const [brandId, setBrandId] = useState();
    const [brief, setBreif] = useState('');
    const [description, setDescription] = useState('');
    const [weekTotal, setWeekTotal] = useState([]);
    const [weekTotalPending, setWeekTotalPending] = useState([]);
    const [weekTotalHold, setWeekTotalHold] = useState([]);
    const [topIcons, setTopIcons] = useState([]);
    const [covers, setcovers] = useState([]);
    const [isIcon, setIsIcon] = useState(true)
    const [filteredImages, setFilteredImages] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectDescription, setSelectDescription] = useState();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isCheck, setIscheck] = useState(false);
    const [location, setLocation] = useState({latitude: '', longitude: ''});
    const [clients, setClients] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [courierSearchValue, setCourierSearchValue] = useState('');
    const [form] = Form.useForm();
    const currentDate = new Date();

    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (title, description, icon) => {
        api.open({
            message: title,
            description:
            description,
            icon: (
                icon
            ),
        });
    };

    const showModal2 = () => {
        setIsModalOpen2(true);
    };

    const handleOk2 = () => {
        setIsModalOpen2(false);
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };

    const theme = useSelector((state) => state.theme.theme);


    useEffect(() => {
        fetchLanguage()
        fetchProducts()
        fetchBottles()
        fetchType()
        fetchImagees()
        fetchBrands()
        totalWeek()
        fetchClients()
        fetchCoruiers()
    }, [])

    const fetchClients = async () => {
        try {
            const data = await getUser('user-clients');
            setClients(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }

    const fetchCoruiers = async () => {
        try {
            const data = await getUser('user-couriers');
            setCouriers(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }

    const totalWeek = async () => {
        try {
            const total = await getTotalLastweekOrder();
            const totalHold = await getTotalHoldLastweekOrder();
            const totalPending = await getTotalPendingLastweekOrder();
            setWeekTotal(total)
            setWeekTotalHold(totalHold)
            setWeekTotalPending(totalPending)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchBrands = async () => {
        try {
            const data = await getBrands();
            setBrands(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }
    const getRowClassName = (record) => {
        switch (record.status) {
            case 'created':
                return 'bg-cyan-500';
            case 'completed':
                return 'bg-green-600';
            case 'pending':
                return 'bg-orange-400';
            default:
                return '';
        }
    };
    const fetchImagees = async () => {
        try {
            const data = await getImages();
            setFilteredImages(data)
            setImages(data);
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
    }
    const fetchBottles = async () => {
        setLoading(true);
        try {
            const data = await getOrdersTeen();
            setBottles(data.last_ten_orders);
            setBottlesOld(data.last_ten_orders)
            console.log('orders')
            console.log(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };
    const formatProductsForServer = () => {
        return selectedProducts.map((product) => {
            console.log(selectedProducts);
            return {
                product_id: product.productId,
                count: product.count
            };
        });
    };
    const onFinish = async (values) => {
        const formattedDate = moment(values.date).format('YYYY-MM-DD');
        console.log("submit")
        console.log(formatProductsForServer())
        try {
            if (edit) {

                await updateProducts(selectedBottles.id, values);
                setBottles(bottles)
                message.success("Categoriya muvoffaqiyatli o`zgardi")
                await fetchBottles()
            } else {

                values.date = formattedDate;
                values.accept_date = moment(values.accept_date).format('YYYY-MM-DD');
                values.products = formatProductsForServer();
                values.status = 'on hold'
                console.log(JSON.stringify(values))
                await makeOrder(JSON.stringify(values));
                setBottles(bottles)
                message.success("Categoriya muvoffaqiyatli qo`shildi")
                await fetchBottles()
            }
        } catch (error) {
            console.log('Error adding bottles types ', error)
        }
    }

    const fetchProducts = async () => {
        try {
            const data = await getProducts('uz');
            setProducts(data)

        } catch (e) {
            console.log(e)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Manzil',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Qo`shimcha ma`lumot',
            dataIndex: 'description',
            key: 'description',
            render: (description) => (
                <div>
                    {description && description.length > 20 ? (
                        <Popover className={'cursor-pointer bg-green-600 rounded p-1 text-white'} placement="rightTop" title={'To`liq ma`lumot'} content={description}>
                            {`${description.substring(0, 20)}...`}
                        </Popover>
                    ) : description}

                </div>
            )
        },
        {
            title: 'Foydalanuvchi',
            dataIndex: 'user',
            key: 'user',
            render: user => (
                <div
                    className={'cursor-pointer bg-blue-500 p-[5px] text-white rounded flex flex-row items-center w-fit'}>
                    <GrCircleInformation className={'text-yellow-400 mr-2'}/>
                    <Popover placement="rightTop" title={`Foydalanuvchi : ${user.fullname}`} content={() => (
                        <div className={'flex flex-col'}>
                            Ismi : {user.fullname}
                            <br/>
                            <p className={'bg-blue-400 rounded text-white cursor-pointer'} onClick={() => {
                                navigator.clipboard.writeText(user['phoneNumber'])
                                message.success('Qo`l telefoni saqlandi ' + user['phoneNumber'])
                            }}> Telefon raqami : {user.phoneNumber}</p>

                            Manzili : {user.address}
                        </div>
                    )}> {user.fullname}</Popover>
                </div>
            )

        },
        {
            title: 'Kuryer',
            dataIndex: ['courier_id','id'],
            key:'courier_id',
            render:((text,record)=>(
                <div>
                    <Select
                        className={'w-[200px]'}
                        onChange={async (e) => {
                            await  changeOrderCourier(record.id,e)
                            fetchBottles()
                            message.success('Kuryer muvoffaqiyatli o`zgartirildi')
                        }}
                        showSearch
                        filterOption={(input, option) => {
                            const userFullName = option.children ? (typeof option.children === 'string' ? option.children.toLowerCase() : '') : '';
                            const userPhoneNumber = option.key ? option.key.toLowerCase() : ''; // Используем option.key вместо option.value
                            const searchValue = input.toLowerCase();

                            const fullNameMatch = userFullName.includes(searchValue);
                            const phoneNumberMatch = userPhoneNumber.includes(searchValue);

                            return fullNameMatch || phoneNumberMatch;
                        }}
                        onSearch={value => setCourierSearchValue(value)}
                        value={record.courier_id} // Объединяем courier_id и id в одно значение
                        placeholder={'Курьер не выбран'}
                    >
                        {couriers && couriers.length > 0 &&
                        couriers
                            .filter(user => user.courier.fullname.toLowerCase().includes(courierSearchValue.toLowerCase()) || user.courier.phoneNumber.toLowerCase().includes(courierSearchValue.toLowerCase()))
                            .map((user) => (
                                <Option key={user.courier.id} value={user.courier.id} >
                                    {' ismi : ' + user.courier.fullname + ' tel: ' + user.courier.phoneNumber}
                                </Option>
                            ))
                        }
                    </Select>
                </div>


            ))
        },
        {
            title: (
                <div className={'flex flex-row items-center w-full bg-orange-400 text-white rounded cursor-pointer'}>
                    <Popover title={'Qo`shimcha ma`lumot xolatlar bo`yicha '} content={() => (
                        <div className={'flex flex-col'}>
                            <p className={'bg-red-600 text-white m-1 rounded p-[3px]'}> on hold Bu buyurtma yaratilgan
                                lekin kutish vaqtida </p>
                            <p className={'bg-orange-600 text-white m-1 rounded p-[3px]'}> pending Bu buyurtma qabul
                                qilingan </p>
                            <p className={'bg-green-600 text-white m-1 rounded p-[3px]'}> complated Bu buyurtma yetqazib
                                berilgan </p>


                        </div>
                    )} className={'flex flex-row items-center cursor-pointer w-full'}>
                        <HiOutlineInformationCircle className={'text-[16px] mr-2 ml-2'}/>
                        Holati
                    </Popover>
                </div>
            ),
            dataIndex: ['status', 'id', 'user', 'address'],
            key: 'status',
            render: (text, record) => (
                <Select onChange={async (e) => {
                    switch (e) {
                        case 'on hold':
                            try {
                                const hold = await holdOrder(record.id)
                                openNotification(`Buyurtma № = ${record.id}  kutish vaqtiga o'zgardi`, (
                                    <div className={'flex p-2 rounded text-white text-[12px] flex-col bg-red-400'}>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <OrderedListOutlined className={'m-2 text-[16px]'}/> Buyurtma tartib raqami
                                            : {record.id}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <UserOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                            : {record.user['fullname']}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2 cursor-pointer bg-blue-600/40 rounded'}
                                           onClick={() => {
                                               navigator.clipboard.writeText(record.user['phoneNumber'])
                                               message.success('Qo`l telefoni saqlandi ' + record.user['phoneNumber'])
                                           }}>
                                            <PhoneOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                            : {record.user['phoneNumber']}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <ImAddressBook className={'m-2 text-[16px]'}/> Manzil
                                            : {record.user['address']}
                                        </p>
                                    </div>

                                ), (<PauseCircleFilled
                                    style={{
                                        color: '#f55273',
                                    }}
                                />))
                            } catch (e) {
                                message.error('Xatolik yuz berdi')
                                console.log(e)
                            }

                            break
                        case 'pending':
                            try {

                                const pending = await pendingOrder(record.id)
                                openNotification(`Buyurtma № = ${record.id}  Qabulga vaqtiga o'zgardi`, (
                                    <div className={'flex p-2 rounded text-white text-[12px] flex-col bg-orange-400'}>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <OrderedListOutlined className={'m-2 text-[16px]'}/> Buyurtma tartib raqami
                                            : {record.id}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <UserOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                            : {record.user['fullname']}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2 cursor-pointer bg-blue-600/40 rounded'}
                                           onClick={() => {
                                               navigator.clipboard.writeText(record.user['phoneNumber'])
                                               message.success('Qo`l telefoni saqlandi ' + record.user['phoneNumber'])
                                           }}>
                                            <PhoneOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                            : {record.user['phoneNumber']}
                                        </p>
                                        <p className={'flex flex-row items-center border-b-2'}>
                                            <ImAddressBook className={'m-2 text-[16px]'}/> Manzil
                                            : {record.user['address']}
                                        </p>
                                    </div>

                                ), (<AiOutlineClockCircle
                                    style={{
                                        color: '#f5a952',
                                    }}
                                />))
                            } catch (e) {
                                message.error('Xatolik yuz berdi'
                                )
                            }
                            break
                        case 'completed':
                            const completed = await completedOrder(record.id)
                            openNotification(`Buyurtma № = ${record.id}  Yetkazib berildiga o'zgardi`, (
                                <div className={'flex p-2 rounded text-white text-[12px] flex-col bg-green-600'}>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <OrderedListOutlined className={'m-2 text-[16px]'}/> Buyurtma tartib raqami
                                        : {record.id}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <UserOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                        : {record.user['fullname']}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2 cursor-pointer bg-blue-600/40 rounded'}
                                       onClick={() => {
                                           navigator.clipboard.writeText(record.user['phoneNumber'])
                                           message.success('Qo`l telefoni saqlandi ' + record.user['phoneNumber'])
                                       }}>
                                        <PhoneOutlined className={'m-2 text-[16px]'}/> Foydalanuvchi
                                        : {record.user['phoneNumber']}
                                    </p>
                                    <p className={'flex flex-row items-center border-b-2'}>
                                        <ImAddressBook className={'m-2 text-[16px]'}/> Manzil : {record.user['address']}
                                    </p>
                                </div>

                            ), (<CheckOutlined
                                style={{
                                    color: '#68f552',
                                }}
                            />))
                            break
                    }
                }} className={'w-[130px] p-0'} defaultValue={record['status']}>
                    <option className={'text-[10px] '} value={'on hold'}>
                        <div
                            className={'flex flex-row items-center justify-center bg-red-600 w-full rounded text-white'}>
                            <BiPauseCircle style={{marginRight: '5px'}}/>
                            on hold
                        </div>
                    </option>
                    <option value={'pending'}>
                        <div
                            className={'flex flex-row items-center justify-center bg-orange-600 w-full rounded text-white'}>
                            <BiTime style={{marginRight: '5px'}}/>
                            pending
                        </div>
                    </option>
                    <option value={'completed'}>
                        <div
                            className={'flex flex-row items-center justify-center bg-green-600 w-full rounded text-white'}>
                            <BiCheckCircle style={{marginRight: '5px'}}/>
                            completed
                        </div>
                    </option>
                </Select>
            )
        },
        {
            title: 'Umumiy summa',
            dataIndex: 'total',
            key: 'total',
            render: (total) => (
                <p className={'bg-green-600 rounded p-[2px] text-white w-fit text-[12px]'}>{total} so`m</p>
            )

        },


    ];


    const fetchOrders = async () => {
        try {
            const orders = await getOrdersTeen();
            setLastTeenOrders(orders);
        } catch (e) {
            console.log(e)
        }

    }
    useEffect(() => {
        fetchOrders()
    }, [])


    const handleSearch = (searchValue) => {
        const filtered = images.filter((value) => {
            return (
                value.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                value.img_category.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
        setFilteredImages(filtered);
    };

    useEffect(() => {
    }, [selectedProducts]);
    const totalPrice = (isCheck) => {
        if (isCheck) {
            let total = 0;
            let elements = [];
            selectedProducts.forEach(value => {
                const product = findProductById(value?.productId);
                const subtotal = value?.count * product?.price;
                total += subtotal;
                elements.push(
                    <div key={value?.productId} className={'text-black border-b-2 rounded'}>
                        {`${product?.name} soni: ${value?.count}, umumiy narxi: ${subtotal} so'm`}
                    </div>
                );
            });
            return elements;
        } else {
            let total = 0;
            selectedProducts.forEach(value => {
                const product = findProductById(value?.productId);
                total += value?.count * product?.price;
            });
            return total;
        }
    };

    const fetchLanguage = async () => {
        try {
            const data = await getLanguage()
            console.log(data)
            setLanguages(data)
        } catch (e) {

        }

    }
    const fetchType = async () => {
        try {
            const data = await getCategoryType()
            console.log(data)
            setTypes(data)
        } catch (e) {

        }
    }
    useEffect(() => {

    }, [bottles])

    const onSearch = (values) => {

        if (values.target.value.length === 0) {
            setBottles(bottlesOld)
        } else {
            setBottles(filterBottles(values?.target?.value.toLowerCase()))
        }

    }

    const iconsHandler = (value) => {
        let newIcons;
        // Создаем новый массив на основе текущего состояния topIcons или covers
        if (!isIcon) {
            newIcons = [...topIcons];
        } else {
            newIcons = [...covers];
        }
        const index = newIcons.indexOf(value);

        if (index === -1) {
            // Если значение не найдено в массиве, добавляем его
            newIcons.push(value);
        } else {
            // Если значение найдено в массиве, удаляем его
            newIcons.splice(index, 1);
        }

        // Обновляем состояние topIcons или covers новым массивом в зависимости от условия
        !isIcon ? setTopIcons(newIcons) : setcovers(newIcons);
        console.log(topIcons);
        console.log(covers);
    };


    // axios.put(baseUrl, data)
    function filterBottles(value) {
        return bottles.filter(bottle => {
            return (bottle.address && bottle.address.toLowerCase().includes(value.toLowerCase())) ||
                (bottle.user && bottle.user.fullname && bottle.user.fullname.toLowerCase().includes(value.toLowerCase())) ||
                (bottle.id && bottle.id.toString().includes(value)) ||
                (bottle.status && bottle.status.toLowerCase().includes(value.toLowerCase()));


        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };
    const handleLanguage = async (e) => {
        setLoading(true);
        try {
            const data = await getProducts(e);
            setBottles(data);
            setBottlesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);

    }

    const handleProductChange = (productId, count) => {
        const updatedProducts = [...selectedProducts];
        const index = updatedProducts.findIndex((product) => product.productId === productId);

        if (index !== -1) {
            // Если продукт уже выбран, обновляем его количество
            updatedProducts[index] = {productId, count};
        } else {
            // Если продукт еще не выбран, добавляем его в список
            updatedProducts.push({productId, count});
        }

        setSelectedProducts(updatedProducts);
    };
    const findProductById = (productId) => {
        return products.find((product) => product.id === productId);
    };
    const search = (value) => {
        message.info(value)
    }
    return <>
        {contextHolder}
        <div
            className={` ${theme === `dark` ? `bg-gray-800` : `bg-white`} transition-all duration-500 w-full h-full flex flex-col items-center rounded-2xl p-6`}>

            <div className={'w-full flex flex-row items-center  justify-center'}>
                <div className={` ${theme === `dark` ? `text-white` : `text-black`}  font-semibold `}>Oxirgi 10 ta
                    buyurtma
                </div>

                <div className={'flex flex-row w-full'}>

                    <div
                        className={'w-[200px] h-[50px] rounded bg-green-800/30 self-center align-center ml-12 flex flex-row items-center'}>
                        <HiOutlineTrendingUp className={'text-4xl text-green-600 bg-white/30 rounded ml-2'}/>
                        <div className={'flex flex-col text-green-600 ml-2 font-bold'}>
                            Kirim :
                            <div>
                                {weekTotal.total_earnings_last_7_days} so`m
                            </div>
                        </div>

                    </div>

                    <div
                        className={'w-[200px] h-[50px] rounded bg-orange-800/30 self-center align-center ml-12 flex flex-row items-center'}>
                        <CgChevronDoubleUp className={'text-4xl text-orange-600 bg-white/30 rounded ml-2'}/>
                        <div className={'flex flex-col text-orange-600 ml-2 font-bold'}>
                            Bajarilmoqda :
                            <div>
                                {weekTotalPending.total_earnings_last_7_days} so`m
                            </div>
                        </div>

                    </div>


                    <div
                        className={'w-[200px] h-[50px] rounded bg-red-800/30 self-center align-center ml-12 flex flex-row items-center'}>
                        <CgChevronDoubleDown className={'text-4xl text-red-600 bg-white/30 rounded ml-2'}/>
                        <div className={'flex flex-col text-red-600 ml-2 font-bold'}>
                            Qabul qilinmagan :
                            <div>
                                {weekTotalHold.total_earnings_last_7_days} so`m
                            </div>
                        </div>

                    </div>


                    <div
                        className={'flex flex-row justify-center items-center bg-blue-800  text-white rounded p-1 ml-4'}>
                        Oxirgi yangilangan sana va vaqt:
                        <ClockCircleOutlined className={'ml-4 mr-4'}/>
                        {'      ' + currentDate.toLocaleTimeString() + '      '}
                        <BsCalendar2Date className={'ml-2 mr-2'}/>
                        {currentDate.toLocaleDateString()}

                    </div>
                    <Popover title={'Buyurtmalar'} content={(<p> Bu tugma ro`yxatni yangilaydi</p>)}>
                        <div
                            onClick={() => {
                                fetchBottles()
                                message.success('Ro`yxat yangilandi')
                            }
                            }
                            className={'w-[35px] h-[35px] rounded bg-blue-200 self-center align-center ml-12 flex flex-row items-center justify-center self-end cursor-pointer'}>
                            <ReloadOutlined className={' text-2xl text-blue-600 bg-white/30 rounded '}/>


                        </div>
                    </Popover>
                </div>


            </div>


            <div className={styles.recent_sales}>

                <div className={'flex flex-col items-start w-full gap-y-2 '}>

                    <div className={`w-full ${theme === 'dark' ? 'custom_dark' : `custom_light`}`}>
                        <Table className={'shadow-2xl w-full  rounded-xl'} pagination={false}
                               rowClassName={getRowClassName} dataSource={bottles} columns={columns}/>
                    </div>

                </div>

            </div>

        </div>
    </>
}
