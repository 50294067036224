import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Switch,
    Table
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {
    addBottleType,
    deleteBottleType,
    getBottleTypes,
    showBottleType,
    updateBottleType
} from "../../api/service_bottlestypes";
import {useSelector} from "react-redux";

export default function BottlesTypes() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [bottlesTypes, setBottlesTypes] = useState([])
    const [bottlesTypesOld, setBottlesTypesOld] = useState([])
    const [selectedBottlesTypes, setSelectedBottlesTypes] = useState([]);

    const [isCashback, setCashback] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);


    const [form] = Form.useForm();

    const theme = useSelector((state) => state.theme.theme);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        fetchBottlesTypes()
    }, [])

    const fetchBottlesTypes = async () => {
        setLoading(true);
        try {
            const data = await getBottleTypes();
            setBottlesTypes(data);
            setBottlesTypesOld(data)
        } catch (error) {
            console.error('Error fetching bottles types:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                await updateBottleType(selectedBottlesTypes.id,values);
                setBottlesTypes(bottlesTypes)
                message.success("тип бутилка успешно изменен в сервер")
                await fetchBottlesTypes()
            } else {
                await addBottleType(values);
                setBottlesTypes(bottlesTypes)
                message.success("тип бутилка успешно добавьлен в сервер")
                await fetchBottlesTypes()
            }
        } catch (error) {
            console.log('Error adding bottles types ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Cashback Percent',
            dataIndex: 'cashbackPercent'
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showBottleType(record.id);
                        setSelectedBottlesTypes(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setEdit(true)
                        setSelectedBottlesTypes(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the bottle type "
                        description="Are you sure to delete this type of bottle ?"
                        onConfirm={async () => {

                            await deleteBottleType(record.id)
                            await fetchBottlesTypes()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Cashback Percent',
            dataIndex: 'cashbackPercent'
        },

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(bottlesTypes, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{},[bottlesTypes])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setBottlesTypes(bottlesTypesOld)
        } else {
            setBottlesTypes(filterBottlestypes(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterBottlestypes(value) {
        return bottlesTypes.filter(bottlestype => {

            return bottlestype?.name.toLowerCase().includes(value) || bottlestype?.size.toLowerCase().includes(value) || bottlestype?.cashbackPercent.toLowerCase().includes(value) ;

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };


    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles_types}>

                <div className={styles.title}>
                    Product Types
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Add a Product type +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the bottle type form" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addBottlesTypes"
                    initialValues={{ remember: true , cashbackPercent: 0}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Product Type Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the bottle type name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Bottle Type Size"
                        name="size"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the bottle type size!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Is Cashback"
                        name="isCashback"
                        valuePropName="checked"
                        getValueFromEvent={(value) => (value ? 1 : 0)}
                    >
                        <Switch onClick={()=>setCashback(!isCashback)} />
                    </Form.Item>

                    <Form.Item
                        label="Cashback Percent"
                        name="cashbackPercent"
                        rules={[
                            {
                                type: 'number',
                                min: 0,
                                max: 100,
                                message: 'Cashback percent must be between 0 and 100!',
                            },
                        ]}
                    >
                    {
                        isCashback? `` :

                                <InputNumber />
                    }
                    </Form.Item>



                </Form>



            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={bottlesTypes} columns={columns}/>
            </div>

            <Drawer  title="Basic Drawer" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Name : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottlesTypes?.name}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Size : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottlesTypes?.size}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Cashback percent : </p>

                        <p className={'text-xl font-semibold'}>{selectedBottlesTypes?.cashbackPercent}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
