// news.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const newsAPI = axios.create({
    baseURL,
});

export const getNews = async (languageCode) => {
    const token = localStorage.getItem('token');
    try {
        const response = await newsAPI.get(`${baseURL}api/news?language=${languageCode}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const addNews = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await newsAPI.post(`${baseURL}api/news`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const deleteNews = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await newsAPI.delete(`${baseURL}api/news/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateNews = async (id, data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await newsAPI.put(`${baseURL}api/news/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};
