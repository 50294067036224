// admins
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const authAPI = axios.create({
    baseURL,
});

export const getImages = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/images`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401 ){
            localStorage.removeItem('token')
        }
        throw error;
    }
};

export const showImages= async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/images/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401 ){
            localStorage.removeItem('token')
        }
        throw error;
    }
};


export const addImages = async (data) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${baseURL}api/images`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401 ){
            localStorage.removeItem('token')
        }
        throw error;
    }
};

export const deleteImages = async (imageID) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.delete(`${baseURL}api/images/${imageID}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateImages = async (imageID, userData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.put(`${baseURL}api/images/${imageID}`, userData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

