import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm, Select, Switch,
    Table
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";
import {Option} from "antd/es/mentions";
import {getDriverWarehouses} from "../../api/service_driver_warehouses";
import {
    addCashBackRecord, deleteCashBackRecord,
    getCashBackHistory,
    showCashBackRecord,
    updateCashBackRecord
} from "../../api/service_cashback_history";
import {useSelector} from "react-redux";

export default function CashbackHistory() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [cashbackHistory, setCashbackHistory] = useState([])
    const [cashbackHistoryOld, setCashbackHistoryOld] = useState([])
    const [selectedCashbackHistory, setSelectedCashbackHistory] = useState([]);

    const [driverWarehouses,setDriverHouses] = useState([])

    const [isCashback, setCashback] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);


    const [form] = Form.useForm();

    const theme = useSelector((state) => state.theme.theme);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        fetchCashbackHistory()
        fetchDriverWarehouses()
    }, [])

    const fetchCashbackHistory = async () => {
        setLoading(true);
        try {
            const data = await getCashBackHistory();
            setCashbackHistory(data);
            setCashbackHistoryOld(data)
        } catch (error) {
            console.error('Error fetching cars types:', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                await updateCashBackRecord(selectedCashbackHistory.id,values);
                setCashbackHistory(cashbackHistory)
                message.success("водитель успешно добавьлен в сервер")
                await fetchCashbackHistory()
            } else {
                await addCashBackRecord(values);
                setCashbackHistory(cashbackHistory)
                message.success("машина успешно добавьлен в сервер")
                await fetchCashbackHistory()
            }
        } catch (error) {
            console.log('Error adding cashback History  ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'User',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: bottle_type => bottle_type.name
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showCashBackRecord(record.id);
                        setSelectedCashbackHistory(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setEdit(true)
                        setSelectedCashbackHistory(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the bottle type "
                        description="Are you sure to delete this type of bottle ?"
                        onConfirm={async () => {

                            await deleteCashBackRecord(record.id)
                            await fetchCashbackHistory()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'User',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: bottle_type => bottle_type.name
        },
        {
            title: 'Name',
            dataIndex: 'name'
        }

    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(cashbackHistory, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{

    },[cashbackHistory])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setCashbackHistory(cashbackHistoryOld)
        } else {
            setCashbackHistory(filterCashbackHistory(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterCashbackHistory(value) {
        return cashbackHistory.filter(cashback => {

            return cashback.name.toLowerCase().includes(value) || cashback.action.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const fetchDriverWarehouses = async () => {
        setLoading(true);
        try {
            const data = await getDriverWarehouses();
            setDriverHouses(data);
        } catch (error) {
            console.error('Error fetching cars types:', error);
        }
        setLoading(false);
    };

    console.log(driverWarehouses)

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Cashback History
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Add a cashback history +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the bottle form" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addCashbackHistory"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >

                    {/*There will be users' list to select*/}
                    {/*<Form.Item*/}
                    {/*    label="Name"*/}
                    {/*    name="name"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*            message: 'Please select the  type!',*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Select>*/}
                    {/*        {driverWarehouses?.map((type) => (*/}
                    {/*            <Option value={type.id}>*/}
                    {/*                {type.name}*/}
                    {/*            </Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}


                    <Form.Item
                        label="User"
                        name="user_id"
                        rules={[
                            {
                                type:"number",
                                required: true,
                                message: 'Please input the user!',
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label="Action"
                        name="action"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the action name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label="Name record"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the record name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                </Form>



            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`}`}>
                <Table className={'shadow-2xl w-full  rounded-xl'} dataSource={cashbackHistory} columns={columns}/>
            </div>

            <Drawer  title="Cashback History" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>User : </p>

                        <p className={'text-xl font-semibold'}>{selectedCashbackHistory?.user_id}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}> Action name : </p>

                        <p className={'text-xl font-semibold'}>{selectedCashbackHistory?.action}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}> Name record : </p>

                        <p className={'text-xl font-semibold'}>{selectedCashbackHistory?.name}</p>
                    </div>
                </div>


            </Drawer>
        </div>
    </div>
}
