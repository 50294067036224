import React from "react";
import {useSelector} from "react-redux";


export default function TimeLineItems(props) {

    const theme = useSelector((state) => state.theme.theme);

    return<>
        <div className={`${theme===`dark`?`text-white`:`text-black`} flex flex-col w-full gap-y-2 transition-all duration-500 `}>
            <div className={'flex items-center justify-between w-full '}>
                <div className={`${theme===`dark`?`text-white/60 bg-gray-900`:`bg-gray-400 text-black/60`} px-2 py-1 font-semibold rounded `}>{props.date}</div>
                <div>{props.when}</div>
            </div>

            <div className={'flex flex-col items-start text-start gap-y-1 w-2/3'}>
                <div className={'text-[17px] font-semibold line-clamp-1'}>
                    {props.title}
                </div>
                <div className={` ${theme===`dark`?`text-white/60 `:` text-black/60`} text-[13px] text-black/60 font-semibold line-clamp-1`}>
                    {props.description}
                </div>
            </div>
        </div>
    </>
}