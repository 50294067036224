import React from "react";
import {BiCart} from "react-icons/bi";
import {GoArrowUpLeft} from "react-icons/go";
import {IconHandler} from "../../../../utils/iconHandler";
import {useSelector} from "react-redux";

export default function Statiscs(props) {

    const theme = useSelector((state) => state.theme.theme);

    return <>
        <div className={` transition-all duration-500 w-full h-full ${theme==='dark'?`bg-gray-800`:`bg-white`} flex flex-row justify-between max-[1250px]:px-3  px-6 py-2 `}>

            <div className={'flex flex-row items-center h-full gap-x-4'}>

                <div className={'max-[1250px]:w-12 max-[1250px]:h-12 max-[1500px]:w-16 max-[1500px]:h-16 w-20 h-20 rounded-full overflow-visible'}>
                    <div style={{borderColor:`${props.color}`}} className={'max-[1250px]:w-12 max-[1250px]:h-12 max-[1500px]:w-16 max-[1500px]:h-16 w-20 h-20 rounded-full border overflow-hidden p-1 flex relative'}>

                        <div style={{backgroundColor:`${props.color}`}} className={'absolute -left-6 top-6 max-[1250px]:w-12 max-[1250px]:h-12 max-[1500px]:w-16 max-[1500px]:h-16 w-20 h-20 rounded-full border border-white z-0 '}></div>
                        <div style={{color:`${props.color}`}} className={`w-full h-full rounded-full duration-500 ${theme===`dark`?`bg-gray-900`:`bg-white`} flex flex-row items-center justify-center shadow-2xl z-10`}>
                            <div className={' max-[1250px]:text-2xl max-[1580px]:text-3xl text-4xl'}>
                                {IconHandler(`${props.icon}`)}
                            </div>
                        </div>

                    </div>
                </div>



                <div className={` ${theme===`dark`?`text-white`:`text-black`} text-start w-auto flex flex-col items-start gap-y-3`}>
                    <div className={'max-[1500px]:text-xl text-3xl line-clamp-1 font-semibold'}>{props.count}</div>
                    <div className={'max-[1500px]:text-[12px] text-[15px] line-clamp-2 font-thin'}>{props.title}</div>
                </div>

            </div>

            <div className={'w-auto h-full flex flex-col justify-end py-2'}>
                {props.percent.length <1? ``: <div className={'flex flex-row items-center text-green-600 max-[1500px]:text-[12px] text-[15px] font-semibold'}>
                    <GoArrowUpLeft className={'translate-y-0.5'} />
                    <div>{props.percent}%</div>
                </div> }

            </div>

        </div>
    </>
}