// admins
import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const authAPI = axios.create({
    baseURL,
});



export const showAdmin = async (id) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${baseURL}api/admins/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response.status === 401 ){
            localStorage.removeItem('token')
        }
        throw error;
    }
};




export const deleteAdmin = async (adminId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.delete(`${baseURL}api/admins/${adminId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

export const updateAdmin = async (adminId, userData) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.put(`${baseURL}api/admins/${adminId}`, userData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
        }
        throw error;
    }
};

