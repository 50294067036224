// store/reducers/themeReducer.js
const initialState = {
    theme: 'light', // начальная тема, вы можете изменить по своему усмотрению
};

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_THEME':
            return {
                ...state,
                theme: action.payload,
            };
        default:
            return state;
    }
};

export default themeReducer;
