import React, {useEffect, useState} from "react";
import styles from "./style.module.css";
import {
    Button,
    Drawer,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Popconfirm,
    Select,
    Table
} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LiaUserShieldSolid} from "react-icons/lia";
import {Excel} from "antd-table-saveas-excel";
import {SiMicrosoftexcel} from "react-icons/si";

import {Option} from "antd/es/mentions";
import {getDriverWarehouseBottles} from "../../api/service_driver_warehouse_bottles";
import {
    addDriverWarehouse,
    deleteDriverWarehouse,
    getDriverWarehouses, showDriverWarehouse,
    updateDriverWarehouse
} from "../../api/service_driver_warehouses";
import {useSelector} from "react-redux";

export default function DriverWarehouses() {

    const [edit, setEdit] = useState(false)

    const [isLoading, setLoading] = useState(false)

    const [driverWarehouse, setDriverWarehouse] = useState([])
    const [driverWarehouseOld, setDriverWarehouseOld] = useState([])
    const [selectedDriverWarehouse, setSelectedDriverWarehouse] = useState([]);

    const [driverWarehouseBottles,setDriverWarehouseBottles] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);


    const [form] = Form.useForm();


    const theme = useSelector((state) => state.theme.theme);



    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setEdit(false)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setEdit(false)
        setIsModalOpen(false);
    };
    const onClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        fetchDriverWarehouse()
        fetchDriverWarehousesBottles()
    }, [])

    const fetchDriverWarehouse = async () => {
        setLoading(true);
        try {
            const data = await getDriverWarehouses();
            setDriverWarehouse(data);
            setDriverWarehouseOld(data)
        } catch (error) {
            console.error('Error fetching warehouse driver warehouses :', error);
        }
        setLoading(false);
    };

    const onFinish = async (values) => {
        console.log("submit")
        try {
            if (edit){
                await updateDriverWarehouse(selectedDriverWarehouse.id,values);
                setDriverWarehouse(driverWarehouse)
                message.success("бутилки успешно изменен в сервер")
                await fetchDriverWarehouse()
            } else {
                await addDriverWarehouse(values);
                setDriverWarehouse(driverWarehouse)
                message.success("бутилки успешно добавьлен в сервер")
                await fetchDriverWarehouse()
            }
        } catch (error) {
            console.log('Error adding warehouse bottles ', error)
        }
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name bottle',
            dataIndex: 'driver_warehouses_bottle',
            key: 'driver_warehouses_bottle',
            render: driver_warehouses_bottle => driver_warehouses_bottle.bottle.name
        },
        {
            title: 'Name warehouse',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Actions',
            render: (record) => {
                return <div className={'w-full flex flex-row items-center justify-center gap-x-2'}>
                    <div onClick={ async()=>{
                        const  response = await showDriverWarehouse(record.id);
                        setSelectedDriverWarehouse(response)
                        setOpen(true)
                    }} className={'action_buttons bg-indigo-500 text-white'}>
                        <EyeOutlined />
                    </div>
                    <div onClick={()=>{
                        setEdit(true)
                        setSelectedDriverWarehouse(record)
                    }} className={'action_buttons bg-amber-500 text-white'}>
                        <EditOutlined/>
                    </div>

                    <Popconfirm
                        title="Delete the bottle type "
                        description="Are you sure to delete this type of bottle ?"
                        onConfirm={async () => {

                            await deleteDriverWarehouse(record.id)
                            await fetchDriverWarehouse()
                        }
                        }
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={deleteButton}
                    >
                        <div className={'action_buttons bg-red-600 text-white'}>
                            <DeleteOutlined/>
                        </div>
                    </Popconfirm>


                </div>
            }
        }
    ];

    const columns2 = [
        {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name bottle',
            dataIndex: 'driver_warehouses_bottle',
            key: 'driver_warehouses_bottle',
            render: driver_warehouses_bottle => driver_warehouses_bottle.bottle.name
        },
        {
            title: 'Name warehouse',
            dataIndex: 'name',
            key: 'name'
        },
    ];

    const handleClick = () => {
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns2)
            .addDataSource(driverWarehouse, {
                str2Percent: true
            })
            .saveAs("Excel.xlsx");
    };

    useEffect(()=>{

    },[driverWarehouse])

    const onSearch =  (values) => {

        if (values.target.value.length === 0){
            setDriverWarehouse(driverWarehouseOld)
        } else {
            setDriverWarehouse(filterDriverWarehouse(values.target.value.toLowerCase()))
        }


    }

    // axios.put(baseUrl, data)
    function filterDriverWarehouse(value) {
        return driverWarehouse.filter(driverWarehouse => {

            return driverWarehouse.driver_warehouses_bottle.bottle.name.toLowerCase().includes(value) || driverWarehouse.name.toLowerCase().includes(value);

        });
    }

    const deleteButton = {
        style: {
            backgroundColor: '#c00900', // Set the default background color
        },
        // Add other props as needed
    };

    const confirmButtonProps = {
        style: {
            backgroundColor: '#0c75ff', // Set the default background color
        },
        // Add other props as needed
    };

    const fetchDriverWarehousesBottles = async () => {
        setLoading(true);
        try {
            const data = await getDriverWarehouseBottles();
            setDriverWarehouseBottles(data);
        } catch (error) {
            console.error('Error fetching bottles :', error);
        }
        setLoading(false);
    };

    console.log(selectedDriverWarehouse)

    return <div className={'w-full'}>
        <div className={styles.container}>
            <div className={styles.header_bottles}>

                <div className={styles.title}>
                    Driver warehouse
                    <LiaUserShieldSolid className={'text-[28px]'}/>
                </div>

                <div className={styles.tools}>
                    <div className={'flex flex-row items-center gap-x-2'}>
                        <Button
                            onClick={showModal}
                            type="primary"
                            className={'bg-green-600'}
                        >
                            Add a Driver warehouse +
                        </Button>

                        <Button type={"primary"} onClick={()=>{handleClick()}}
                                className={'bg-blue-400 flex flex-row text-xl items-center'}><SiMicrosoftexcel />
                        </Button>
                    </div>

                    <div className={'flex flex-row items-center'}>
                        <div className={`font-sans font-semibold ${theme ==='dark'? 'text-white' : 'text-black/60'} text-black/60 mr-4`}>Search</div>
                        <Input
                            placeholder="input search text"
                            allowClear
                            onChange={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>

                </div>

            </div>

            <Modal title="Fill the bottle form" open={isModalOpen || edit} okButtonProps={confirmButtonProps} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="addWarehouseBottles"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Bottle name"
                        name="DriverWarehousesBottle_id"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the bottle type!',
                            },
                        ]}
                    >
                        <Select>
                            {driverWarehouseBottles?.map((type) => (
                                <Option value={type.id}>
                                    {type.bottle?.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Bottle driver warehouse name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the driver warehouse name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>



            </Modal>
            <div className={`w-full ${theme === 'dark'? 'custom_dark' : `custom_light`} `}>
                <Table className={'w-full shadow-2xl  rounded-xl'} dataSource={driverWarehouse} columns={columns}/>
            </div>

            <Drawer  title="Bottle" onClose={onClose} open={open}>

                <div className={'flex w-full flex-col items-center text-start gap-y-2'}>

                    <div className={'flex flex-row items-center gap-x-2'}>
                        <p className={'text-xl'}>Name : </p>

                        <p className={'text-xl font-semibold'}>{selectedDriverWarehouse?.bottle?.name}</p>
                    </div>

                    <div className={'flex flex-row items-center gap-x-2 '}>
                        <p className={'text-xl'}>Bottle count : </p>

                        <p className={'text-xl font-semibold'}>{selectedDriverWarehouse?.name}</p>
                    </div>

                </div>


            </Drawer>
        </div>
    </div>
}
