import React, { useState} from "react";
import styles from "./style.module.css";

import drop from "../../assets/images/basket.png";
import {FaFirstOrder, FaRegAddressCard, FaWarehouse} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {setTheme} from "../../store/actions/themeActions";
import {Link} from "react-router-dom";

import Sider from "antd/es/layout/Sider";

import {
    BuildFilled, DribbbleOutlined,
    HomeOutlined, PictureOutlined, SlidersOutlined
} from '@ant-design/icons';
import {HiNewspaper, HiOutlineUserGroup} from "react-icons/hi";
import {LiaFirstOrder, LiaUserAstronautSolid, LiaUserShieldSolid} from "react-icons/lia";
import {BsBoxFill, BsCashCoin, BsTruck, BsType} from "react-icons/bs";
import {PiBeerBottleLight, PiBrandyBold, PiTelegramLogo, PiTruckLight} from "react-icons/pi";
import {GiHomeGarage, GiWaterGallon} from "react-icons/gi";
import {IoCardOutline, IoConstruct} from "react-icons/io5";
import {LuWarehouse} from "react-icons/lu";
import {VscHistory} from "react-icons/vsc";
import {setOpen} from "../../store/actions/openActions";
import {CiMail} from "react-icons/ci";
import {HiLanguage} from "react-icons/hi2";
import {FiType} from "react-icons/fi";
import {BiCategory, BiSolidTerminal} from "react-icons/bi";
import {MdBookmarkBorder, MdCategory} from "react-icons/md";
import {TbCategory2, TbKeyframeAlignHorizontal, TbMenuOrder} from "react-icons/tb";
import {CgProductHunt} from "react-icons/cg";
import {RiListOrdered} from "react-icons/ri";
import {AiOutlineSliders} from "react-icons/ai";


export default function Sidebar() {

    const [activeDash,setActiveDash] = useState(0);

    const [subActiveDash,setSubActiveDash] = useState(0);

    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme.theme);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        dispatch(setTheme(newTheme));
    };

    const Theme = {
        color:`${theme === `dark` ? `black` : 'white'}`,
    }

    const isOpen = useSelector((state) => state.open.isOpen);

    const borderedShapes = [
        <div className={`w-12 h-full justify-self-end right-0 ${theme === `dark`? `bg-black`: `bg-[#f3f5f6]`} relative`}>
            <div style={{boxShadow:`14px 12px 0px 12px ${theme === `dark`? `black`: `#f3f5f6`}`,borderBottomRightRadius: `100%`}}
                 className={`absolute -top-[50%] right-0 h-[50%] w-full ${theme===`dark`? `bg-[#4d4d4d]` : `bg-[#1e40ae]`} `}></div>
            <div style={{boxShadow:`14px -12px 0px 12px ${theme === `dark`? `black`: `#f3f5f6`}`,borderTopRightRadius: `100%`}}
                 className={` absolute -bottom-[50%] right-0 h-[50%] w-full  ${theme==='dark'? `bg-[#4d4d4d]` : `bg-[#1e40ae]`}`}></div>
        </div>
    ]

    const Dashboard = [
        {
            icon: <HomeOutlined className={'text-[25px]'}/>,
            title: 'Dashboard',
            link: '/',
        },
        {
            icon: <PictureOutlined className={'text-[25px]'}/>,
            title: 'Rasmlar',
            link: '/images',
        },
        {
            icon: <HiOutlineUserGroup className={'text-[25px]'} />,
            title: 'Foydalanuvchilar',
            link: '#',
            users: [
                {
                    icon: <HiOutlineUserGroup className={'text-[20px]'} />,
                    title:'Barcha ',
                    link:'/users'
                },
                {
                    icon: <LiaUserShieldSolid className={'text-[20px]'} />,
                    title:'Admin',
                    link:'/admins'
                },

                {
                    icon: <BsTruck className={'text-[20px]'} />,
                    title:'Kuryer',
                    link:'/drivers'
                },
                {
                    icon: <PiTelegramLogo className={'text-[20px]'} />,
                    title:'Client',
                    link:'/clients'
                },
            ]
        },
        {
            icon: <IoCardOutline className={'text-[25px]'}/>,
            title: 'Clienlar kartasi',
            link: '/user-cards',
        },
        {
            icon: <PiTruckLight className={'text-[25px]'}/>,
            title: 'Haydovchilar kartasi',
            link: '/drivers-cards',
        },
        {
            icon: <HiLanguage className={'text-[25px]'}/>,
            title: 'Til',
            link: '/language',
        },
        {
            icon: <IoConstruct className={'text-[25px]'}/>,
            title: 'Icons',
            link: '/icons',
        },


        {
            icon: <FiType className={'text-[25px]'}/>,
            title: 'Categoriya turi',
            link: '/category_type',
        },
        {
            icon: <TbCategory2 className={'text-[25px]'}/>,
            title: 'Categoriya',
            link: '/category',
        },
        {
            icon: <CgProductHunt className={'text-[25px]'}/>,
            title: 'Mahsulotlar',
            link: '/products',
        },
        {
            icon: <FaWarehouse className={'text-[25px]'}/>,
            title: 'Ombor',
            link: '/warehouses',
        },
        // {
        //     icon: <FaWarehouse className={'text-[25px]'}/>,
        //     title: 'Ombor',
        //     link: '/warehouses',
        // },
        {
            icon: <MdBookmarkBorder className={'text-[25px]'}/>,
            title: 'Buyurtmalar',
            link: '/orders',
        },

        {
            icon: <HiNewspaper className={'text-[25px]'}/>,
            title: 'Yangiliklar',
            link: '/news',
        },
        {
            icon: <TbKeyframeAlignHorizontal className={'text-[25px]'}/>,
            title: 'Slider',
            link: '/slider',
        },
        {
            icon: <BiSolidTerminal className={'text-[25px]'}/>,
            title: 'Amallar',
            link: '/history',
        },
        // {
        //     icon: <BsBoxFill  className={'text-[25px]'} />,
        //     title: 'Products',
        //     link: '/bottles',
        // },
        // {
        //     icon: <CiMail  className={'text-[25px]'}/>,
        //     title: 'Mailed Products',
        //     link: '/bottles_types',
        // },
        // {
        //     icon: <IoCardOutline className={'text-[25px]'}/>,
        //     title: 'Cards',
        //     link: '/cards',
        // },
        // {
        //     icon: <FaRegAddressCard  className={'text-[25px]'}/>,
        //     title: 'Driver Cards',
        //     link: '/driver_cards',
        // },
        // {
        //     icon: <BsTruck className={'text-[25px]'}/>,
        //     title: 'Cars',
        //     link: '/cars',
        // },
        // {
        //     icon: <GiHomeGarage   className={'text-[25px]'}/>,
        //     title: 'Driver Warehouse',
        //     link: '/driver_warehouse',
        // },
        // {
        //     icon: <GiHomeGarage   className={'text-[25px]'}/>,
        //     title: 'Driver Wr Products',
        //     link: '/driver_warehouse_bottles',
        // },
        // {
        //     icon: <LuWarehouse className={'text-[25px]'}/>,
        //     title: 'Warehouse',
        //     link: '/warehouse',
        // },
        // {
        //     icon: <LuWarehouse className={'text-[25px]'}/>,
        //     title: 'Warehouse Products',
        //     link: '/warehouse_bottle',
        // },
        // {
        //     icon: <BsCashCoin  className={'text-[25px]'}/>,
        //     title: 'Cashback History',
        //     link: '/cashback_history',
        // },
        // {
        //     icon: <VscHistory  className={'text-[25px]'}/>,
        //     title: 'History',
        //     link: '/history',
        // },

    ]

    const Submenu = Dashboard[activeDash]?.users?.length

    return<>
        <Sider width={isOpen?300:90} style={{backgroundColor:`${theme===`dark` ? `#4d4d4d` : `#1e40af`}`}} className={` rounded-3xl ${styles.site_layout_background}`}>

            <div className={`${isOpen? `` : `pb-12`} ${theme===`dark`? `bg-[#4d4d4d]` : `bg-[#1e40af]`} sticky top-0 z-20 rounded-br-3xl`}>
                <div className={styles.logo_place}>
                    <Link to={'/'} className={'flex flex-row items-center '}>
                        <img src={drop} className={'bg-white p-1 rounded'} style={{width:'60px',height:'60px', objectFit:'contain'}}/>
                        <span className={'text-2xl text-white ml-4 whitespace-nowrap font-bold font-mono text-center'}>Humo Water</span>
                    </Link>

                </div>
            </div>


            <div className={`${styles.menu} ${isOpen? '' : `pr-2`}`}>
                <div style={{transform:`translateY(${activeDash * 65}px)`}} className={`w-[100%] h-16 rounded-l-xl duration-500 ${theme === `dark`? `bg-black`: `bg-[#f3f5f6]`} absolute `}>
                    <div className={'relative w-full h-full flex flex-row justify-end pr-5'}>
                        {isOpen? borderedShapes :``}
                    </div>
                </div>
                {Dashboard?.map((value, index)=> <div onClick={()=>{
                    setActiveDash(index)
                    value.users?.length > 0 ? setOpen(true) : console.log('')
                }} style={{height:`${Submenu > 0? activeDash===index? Submenu * 60 : 60 : `65`}px`,color:`${activeDash===index ? `black` : `white`}`}}  className={activeDash === index? styles.menu_item_active : styles.menu_item}>

                    <div className={'w-full  flex flex-col items-start mt-5 relative'}>
                        <Link style={{color:`${activeDash===index?`#35a1fb`:``}`}} to={`${value.link}`} className={'flex  flex-row items-center'}>
                            {value.icon}
                            <div className={styles.links}>{value.title}</div>
                        </Link>

                        {activeDash===index? Submenu > 0 ?
                            <div style={{height:`${Submenu*43}px`}} className={'bg-white/30  w-[90%] duration-100 pl-1 overflow-hidden  flex flex-col absolute left-0 top-14 rounded-2xl '}>

                                {activeDash===index? value.users?.map((value, index)=> <Link onClick={()=>{
                                    dispatch(setOpen(true))
                                    setSubActiveDash(index)
                                    console.log(subActiveDash)
                                }}  className={'pt-3 relative'} key={index} to={`${value.link}`}>

                                    <div style={{color:`${subActiveDash===index?`#35a1fb`:`white`}`}} className={`flex flex-row items-center ${isOpen? `ml-4` : ``}`}>
                                        <div className={isOpen?``: `ml-1`}>
                                            {value.icon}
                                        </div>

                                        <div className={styles.sub_links}>{value.title}</div>
                                    </div>
                                </Link>) : ``}
                            </div>
                            : ``

                            : ``
                        }


                    </div>

                </div>)}

            </div>
        </Sider>
    </>
}
