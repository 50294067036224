// src/store/reducers/openReducer.js
const initialState = {
    isOpen: true,
};

const openReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_OPEN':
            return {
                ...state,
                isOpen: action.payload,
            };
        default:
            return state;
    }
};

export default openReducer;
